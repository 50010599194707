import React, { useState } from 'react'
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import axios from 'axios';
import siteSetting from "../components/siteSetting"

const ChatFooter = ({ socket }) => {
  const [message, setMessage] = useState("")
  const handleTyping = (e) => {
    if (e.target.value.length > 0) {
      socket.emit("typing", `${localStorage.getItem("name")} กำลังพิมพ์...`)
    } else {
      socket.emit("typing", ``)
    }
  }

  const handleSendMessage = (e) => {
    e.preventDefault()
    if (message.trim() && localStorage.getItem("name")) {
      socket.emit("message",
        {
          text: message,
          name: localStorage.getItem("name"),
          id: `${socket.id}${Math.random()}`,
          socketID: socket.id
        }
      )

      axios({
        method: 'post',
        url: siteSetting.apiUrl + '/chat/addnew',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/json'
        },
        data: {
          msg: message,
        }
      }).then(function (response) {
        //console.log(response);
      });



    }
    socket.emit("typing", ``)
    setMessage("")
  }
  return (
    <div className='p-5'>
      <form className='flex w-full  items-end flex-wrap' onSubmit={handleSendMessage}>
        <div className='flex items-center w-full'>
          <input
            type="text"
            placeholder='เขียนข้อความ'
            className='w-full block p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:outline-none'
            value={message}
            onChange={e => setMessage(e.target.value)}
            onInput={handleTyping}

          />
          <button className="p-4">
            <SendOutlinedIcon />
          </button>
        </div>
      </form>
    </div>
  )
}

export default ChatFooter