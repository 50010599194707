/*
const siteSetting = {
    siteName: '8Content',
    apiUrl: 'https://crm-api.8content.com',
    docUrl: 'https://crm-pdf.8content.com',
    chatUrl: 'chat.iapi.8content.com',
    expUrl: 'https://iapi.8content.com/domain/willexp'
}
*/

const siteSetting = {
    siteName: '8Content',
    apiUrl: 'https://api-crm-demo.8content.com',
    docUrl: 'https://crm-pdf-demo.8content.com',
    chatUrl: 'chat.iapi.8content.com',
    expUrl: 'https://iapi.8content.com/domain/willexp'
}



export default siteSetting