import EmptyPage from "../emptyPage"
import { useEffect, useState } from 'react';

import DragAndDropImageUpload from "../components/imageUpload";

export default function UpdateLog({ socket }) {
    const [newMsg, setNewMsg] = useState(true);

    useEffect(() => {
        socket.on("messageResponse", () => {
            localStorage.setItem('newMsg', 'true');
            setNewMsg(false);
        });
    }, [socket])

    useEffect(() => {
        if (localStorage.getItem('newMsg')) {
            setNewMsg(false);
        }
    }, [])

    const pageContent = (
        <>
            {/*<h1 className="text-2xl font-bold">Change Log</h1>

            <h2 className="text-xk font-bold my-4 mb-2">V1.0.2 (10-Fab-2023)</h2>
            <ul className="list-disc ml-5">
                <li>Add drag and drop Reorder Document product list.</li>
                <li>Add Stock management to each product setting.</li>
                <li>Add Line notify to notify lower stock.</li>
            </ul>

            <h2 className="text-xk font-bold my-4 mb-2">V1.0.1 (15-Jan-2023)</h2>
            <ul className="list-disc ml-5">
                <li>Change width of doc template</li>
    </ul>*/}
            <div style={{ width: '350px' }}>

                <DragAndDropImageUpload />
            </div>
        </>
    );


    return (
        <EmptyPage
            newMsg={newMsg}
            pageTitle="Update log"
            formWidth={'550px'}
            pageContent={pageContent}
        />
    )
}