import { useState, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ClearAllOutlinedIcon from '@mui/icons-material/ClearAllOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Input from '@mui/material/Input';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import Badge from '@mui/material/Badge';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import siteSetting from "./siteSetting";
import axios from 'axios';
import Drawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
//import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import FaceOutlinedIcon from '@mui/icons-material/FaceOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DragAndDropImageUpload from '../components/imageUpload'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function AppHeader({ disableFrom, submitSearch, pageTitle, searchTextChange, notiCount, newMsg }) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const openUserMenu = Boolean(anchorEl);
  const handleClickUserMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorEl(null);
  };

  const [open, setOpen] = useState(false);
  const [notificationList, setNotificationList] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [userProfile, setUserProfile] = useState({
    email: '',
    name: '',
    lname: ''
  });
  const [inputError, setInputError] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const [previewSignature, setPreviewSignature] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [openNoti, setOpenNoti] = useState(false);
  const [notiMsg, setNotiMsg] = useState({
    msg: '',
    type: 'error'
  });
  const handleCloseNoti = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenNoti(false);
  };

  const handleCkickBell = () => {
    localStorage.setItem('noticount', 0);
    document.title = pageTitle + ' - ' + siteSetting.siteName;
    axios({
      method: 'get',
      url: siteSetting.apiUrl + '/noti-list',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/json'
      },
    }).then(function (response) {
      setNotificationList(response.data);
      setOpenDrawer(true);
    });
  }

  const getUserProfile = () => {

    axios({
      method: 'get',
      url: siteSetting.apiUrl + '/user/profile',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/json'
      },
    }).then(function (response) {
      if (response.data.status === 200) {
        setUserProfile(prev => ({
          ...prev,
          username: response.data.data.username,
          email: response.data.data.email,
          name: response.data.data.name,
          lname: response.data.data.lname
        }));
        if (response.data.data.signature !== '1') {
          setPreviewSignature(`${siteSetting.docUrl}/${response.data.data.signature}`);
        }
      }
    });
  }

  const readNotiList = (id) => {
    axios({
      method: 'post',
      url: `${siteSetting.apiUrl}/noti-list/update/${id}`,
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/json'
      },
    });
  }

  const updateUserProfile = () => {
    if (userProfile.email === '' || userProfile.name === '' || userProfile.lname === '') {
      setInputError(true);
      return;
    }

    setDisabled(true);

    axios({
      method: 'put',
      url: siteSetting.apiUrl + '/user/profile/update/',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/json'
      },
      data: {
        email: userProfile.email,
        name: userProfile.name,
        lname: userProfile.lname,
        signature: croppedImage
      }
    }).then(function (response) {
      setDisabled(false);
      if (response.data.status === 500) {
        setNotiMsg(prev => ({
          ...prev,
          msg: response.data.msg,
          type: 'error'
        }));
        setOpenNoti(true);
      }
      if (response.data.status === 200) {
        setNotiMsg(prev => ({
          ...prev,
          msg: response.data.msg,
          type: 'success'
        }));
        setOpenNoti(true);
        setOpen(false);
      }
    });
  }

  const toggleDrawer = () => {
    setOpenDrawer(false);
  }

  const onCrop = (croppedImage) => {
    setCroppedImage(croppedImage);
  };

  return (
    <>
      <div className="flex mainHeader border-b border-slate-150 px-7 py-5 justify-between items-center bg-white">
        <div className="flex items-center space-x-6">
          <button onClick={() => {
            if (localStorage.getItem('collapsed') === 'collapsed') {
              document.querySelector('.main-side-bar').classList.remove('collapsed');
              localStorage.setItem('collapsed', 'false');
            } else {
              document.querySelector('.main-side-bar').classList.add('collapsed');
              localStorage.setItem('collapsed', 'collapsed');
            }
          }}>
            <ClearAllOutlinedIcon />
          </button>

          {!disableFrom && <form onSubmit={submitSearch}>
            <FormControl variant="standard">
              <Input
                id="input-with-icon-adornment"
                placeholder={`ค้นหา${pageTitle}`}
                startAdornment={
                  <InputAdornment position="start">
                    <button type="submit">
                      <SearchOutlinedIcon />
                    </button>
                  </InputAdornment>
                }
                onChange={(e) => { searchTextChange(e.target.value) }}
              />
            </FormControl>
          </form>}
        </div>
        <div className="flex items-center space-x-4">
          <span>
            <span>Howdy, {localStorage.getItem('name')}</span>
          </span>
          <Badge color="primary" variant="dot" invisible={newMsg} className='cursor-pointer bg-indigo-50 p-2 rounded-md' id='top-chat' onClick={() => {
            localStorage.removeItem('newMsg');
            navigate('/chat');
          }}>
            <QuestionAnswerOutlinedIcon sx={{ color: '#3730a3' }} />
          </Badge>
          <Badge badgeContent={notiCount} color="primary" onClick={handleCkickBell} className='cursor-pointer bg-indigo-50 p-2 rounded-md' id='top-notification'>
            <NotificationsNoneOutlinedIcon sx={{ color: '#3730a3' }} />
          </Badge>
          <Avatar className='cursor-pointer' sx={{ bgcolor: '#3730a3' }} onClick={handleClickUserMenu}>{localStorage.getItem('name').charAt(0)}</Avatar>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openUserMenu}
            onClose={handleCloseUserMenu}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 25,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
          >
            <MenuItem onClick={() => {
              getUserProfile();
              handleCloseUserMenu();
              setOpen(true)
            }}>
              <FaceOutlinedIcon sx={{ color: '#595c60' }} />
              <span className='ml-4'>โปรไฟล์ของฉัน</span>
            </MenuItem>
            <MenuItem onClick={() => navigate('/logout')}>
              <LogoutOutlinedIcon sx={{ color: '#595c60' }} />
              <span className='ml-4'>ออกจากระบบ</span>
            </MenuItem>
          </Menu>

        </div>
      </div>

      <Drawer
        anchor={'right'}
        open={openDrawer}
        onClose={toggleDrawer}
      >

        <List>
          {notificationList !== null && notificationList.length > 0 ?

            Object.entries(notificationList).map(([k, item]) => (
              <div key={k}>
                <ListItem >
                  <div className='flex gap-4 w-96 items-center rounded-md py-2'>
                    <div className='w-1.5/12'>
                      <div className='aspect-square bg-indigo-100 w-full rounded-full flex items-center justify-center p-2'>
                        <NotificationsActiveOutlinedIcon sx={{ fontSize: 30, color: '#4338ca' }} />
                      </div>
                    </div>
                    <div className='w-9/12'>
                      <h4 className='text-md font-bold text-gray-800'>{item.title}</h4>
                      <p className='text-gray-600 text-sm'>
                        {item.start && item.end && `${item.start} ถึง ${item.end}`}
                        {item.description && item.description}
                      </p>
                    </div>
                    <div className='w-1.5/12'>
                      {item.hide &&
                        <button onClick={(e)=> {
                          readNotiList(item.hide)
                          handleCkickBell()
                          }}>
                          <CloseOutlinedIcon />
                        </button>
                      }
                    </div>
                  </div>
                </ListItem>
                <Divider />
              </div>
            ))
            :
            <ListItem>
              <div className='w-96'>
                <div className='text-center'>
                  ยังไม่มีการแจ้งเตือน
                </div>
              </div>
            </ListItem>
          }
        </List>

      </Drawer>

      <Dialog
        maxWidth={'lg'}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>แก้ไขโปรไฟล์</DialogTitle>
        <DialogContent>
          <div className="mb-4" style={{ minWidth: '300px' }}>
            <TextField
              error={inputError && userProfile.email === '' ? true : false}
              helperText={inputError && userProfile.email === '' && 'กรุณากรอกอีเมล'}
              label="อีเมล"
              className="w-full mb-4"
              placeholder="อีเมล"
              variant="standard"
              type="email"
              onChange={(e) => {
                setUserProfile(prev => ({
                  ...prev,
                  email: e.target.value,
                }));
              }}
              value={userProfile.email}
            />
          </div>

          <div className="mb-4" style={{ minWidth: '300px' }}>
            <TextField
              error={inputError && userProfile.name === '' ? true : false}
              helperText={inputError && userProfile.name === '' && 'กรุณากรอกชื่อ'}
              label="ชื่อ"
              className="w-full"
              placeholder="ชื่อ"
              variant="standard"
              type="email"
              onChange={(e) => {
                setUserProfile(prev => ({
                  ...prev,
                  name: e.target.value,
                }));
              }}
              value={userProfile.name}
            />
          </div>
          <div className="mb-8" style={{ minWidth: '300px' }}>
            <TextField
              error={inputError && userProfile.lname === '' ? true : false}
              helperText={inputError && userProfile.lname === '' && 'กรุณากรอกนามสกุล'}
              label="นามสกุล"
              className="w-full"
              placeholder="นามสกุล"
              variant="standard"
              type="email"
              onChange={(e) => {
                setUserProfile(prev => ({
                  ...prev,
                  lname: e.target.value,
                }));
              }}
              value={userProfile.lname}
            />
          </div>
          <div className="mb-0" style={{ minWidth: '300px' }}>
            <label className='mb-2 block'>ลายเซ็นเอกสาร (รูปแบบ jpg, jpeg, png ขนาด 1,000 x 350 px) </label>
            <DragAndDropImageUpload onCrop={onCrop} defaultImage={previewSignature} />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>ยกเลิก</Button>
          <Button onClick={() => {
            updateUserProfile();
          }} disabled={disabled}>ตกลง</Button>
        </DialogActions>
      </Dialog>
      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={openNoti} autoHideDuration={6000} onClose={handleCloseNoti}>
        <Alert onClose={handleCloseNoti} severity={notiMsg.type} sx={{ width: '100%' }}>
          {notiMsg.msg}
        </Alert>
      </Snackbar>
    </>
  )
}