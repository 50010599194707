import Skeleton from '@mui/material/Skeleton';

export default function PageSKT(props) {
    return (
        Array(parseInt(props.tr))
            .fill(1)
            .map((card, index) => (
                <tr key={index}>
                    {Array(parseInt(props.td))
                        .fill(1)
                        .map((ccc, iii) => (
                            <td key={iii} className='p-4'>
                                <Skeleton />
                            </td>
                        ))}
                </tr>
            ))
    )
}