import EmptyPage from "../emptyPage"
import siteSetting from '../components/siteSetting';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { th } from 'react-date-range/dist/locale';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement } from 'chart.js';
import { Pie, Bar } from 'react-chartjs-2';
import { useNavigate  } from "react-router-dom";
ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Tooltip, Legend);

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function ReportProduct({ socket }) {
    const [newMsg, setNewMsg] = useState(true);

    useEffect(() => {
        socket.on("messageResponse", () => {
            localStorage.setItem('newMsg', 'true');
            setNewMsg(false);
        });
    }, [socket])

    useEffect(() => {
        if (localStorage.getItem('newMsg')) {
            setNewMsg(false);
        }
    }, [])

    const [data, setData] = useState(null);
    const navigate = useNavigate();
    const convertDate = (date) => {
        const today = new Date(date);
        const year = today.getFullYear();
        const month = today.getMonth() + 1;
        const day = today.getDate();
        const formattedMonth = (month < 10) ? '0' + month : month;
        return year + '-' + formattedMonth + '-' + day;
    }

    const [open, setOpen] = useState(false);
    const sevenDaysEarlier = new Date();
    const [stateD, setStateD] = useState([{
        startDate: new Date(sevenDaysEarlier.setDate(sevenDaysEarlier.getDate() - 15)),
        endDate: new Date(),
        key: 'selection',
        color: '#3730a3',
    }]);
    const [queryParams, setQueryParams] = useState({
        start: convertDate(new Date(sevenDaysEarlier.setDate(sevenDaysEarlier.getDate() - 15))),
        end: convertDate(new Date())
    });

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const startFromURL = searchParams.get('start');
        const endFromURL = searchParams.get('end');

        if (startFromURL && endFromURL) {
            //console.log('found');
            setStateD((prevState) => {
                const updatedState = [...prevState];
                updatedState[0].startDate = new Date(startFromURL);
                updatedState[0].endDate = new Date(endFromURL)
                return updatedState;
            });
            setQueryParams(prev => ({
                ...prev,
                start: startFromURL,
                end: endFromURL
            }));
        }
    }, []);


    useEffect(() => {
        axios({
            method: 'get',
            url: `${siteSetting.apiUrl}/report/product-sale?start=${queryParams.start}&end=${queryParams.end}`,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
        }).then(function (response) {
            setData(response.data);
        });
    }, [queryParams]);

    function fixedNUM(num, precision = 2) {
        if (num === null) {
            return '0.00';
        }
        var roundedx = (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
        var commasx = roundedx.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return commasx;
    }

    const handleChangeReport = (event) => {
        navigate(`${event.target.value}?start=${queryParams.start}&end=${queryParams.end}`)
    };
    const [expanded, setExpanded] = useState('0');
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const pageContent = (
        <>
            <div className="flex justify-between items-center">
                <h1 className="text-xl font-bold">รายงานวันที่ {`${convertDate(stateD[0].startDate)} ถึง ${convertDate(stateD[0].endDate)}`}</h1>
                <div className="flex gap-4">
                    <FormControl variant="standard" >
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={'/report/product'}
                            label="ประเภทรายงาน"
                            onChange={handleChangeReport}
                        >
                            <MenuItem value={'/report'}>รายงานสรุปยอดขาย</MenuItem>
                            <MenuItem value={'/report/product'}>รายงานยอดขายแยกตามสินค้า</MenuItem>
                            <MenuItem value={'/report/customer'}>รายงานยอดขายแยกตามลูกค้า</MenuItem>
                        </Select>
                    </FormControl>
                    <Button variant="contained" className='flex gap-2 items-center px-4' onClick={() => setOpen(true)}>
                        <TodayOutlinedIcon />
                        เลือกช่วงวันที่
                    </Button>
                </div>
            </div>


            <div className="my-4" style={{ height: 'auto' }}>
                <div className="flex gap-4">
                    <div className="w-4/12">
                        <Card sx={{ height: '100%'}}>
                            <CardContent>
                                {data !== null &&
                                    <Pie
                                        options={{
                                            plugins: {
                                                legend: {
                                                    position: 'top',
                                                    labels: {
                                                        font: {
                                                            size: 13.2,
                                                            family: 'Noto Sans Thai',
                                                        }
                                                    }
                                                },
                                                title: {
                                                    display: true,
                                                    text: 'สินค้าขายดี',
                                                    font: {
                                                        family: 'Noto Sans Thai',
                                                    }
                                                },
                                                tooltip: {
                                                    titleFont: { size: 13.2, family: "Noto Sans Thai" },
                                                    bodyFont: { size: 14.2, family: "Noto Sans Thai" },
                                                }
                                            },
                                        }}

                                        data={{
                                            labels: data.map(product => product.productSKU),
                                            datasets: [
                                                {
                                                    label: "จำนวนที่ขายได้",
                                                    data: data.map(product => product.qty),
                                                    backgroundColor: [
                                                        '#6366f1',
                                                        '#a78bfa',
                                                        '#38bdf8',
                                                        '#34d399',
                                                        '#fde047',
                                                        '#fb7185',
                                                        '#e879f9'
                                                    ]
                                                }
                                            ],
                                        }}
                                    />
                                }
                            </CardContent>
                        </Card>
                    </div>
                    <div className="w-8/12">
                        <Card sx={{ height: '100%'}}>
                            <CardContent>
                                {data !== null &&
                                    <Bar
                                        options={{
                                            indexAxis: 'y',
                                            elements: {
                                                bar: {
                                                    borderWidth: 2,
                                                },
                                            },
                                            responsive: true,
                                            plugins: {
                                                legend: {
                                                    display: false,
                                                },
                                                title: {
                                                    display: true,
                                                    text: 'ยอดขาย',
                                                    font: {
                                                        size: 13.2,
                                                        family: 'Noto Sans Thai',
                                                    }
                                                },
                                                tooltip: {
                                                    titleFont: { size: 13.2, family: "Noto Sans Thai" },
                                                    bodyFont: { size: 14.2, family: "Noto Sans Thai" },
                                                }
                                            },
                                        }}
                                        data={{
                                            labels: data.map(product => product.productSKU),
                                            datasets: [
                                                {
                                                    label: "ยอดขาย",
                                                    data: data.map(product => product.inRowSumPrice),
                                                    backgroundColor: "#6366f1",
                                                    borderColor: "#6366f1",
                                                    pointBackgroundColor: "#6366f1",
                                                    pointBorderColor: "#6366f1",
                                                }
                                            ],
                                        }}
                                    />
                                }
                            </CardContent>
                        </Card>
                    </div>
                </div>
            </div>
            <Card>
                <CardContent>
                    {data !== null ? (Object.entries(data).map(([key, product]) => (
                        <Accordion key={key} expanded={expanded === key} onChange={handleChange(key)}>
                            <AccordionSummary>
                                รหัสสินค้า: {product.productSKU}
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="flex w-full">
                                    <div className="w-6/12 p-2">ลูกค้า</div>
                                    <div className="w-2/12 p-2 text-right">วันที่</div>
                                    <div className="w-2/12 p-2 text-right">จำนวนที่ขาย</div>
                                    <div className="w-2/12 p-2 text-right">ยอดรวม</div>
                                </div>
                                {Object.entries(product.purchases).map(([kk, purchase]) => (
                                    <div key={kk} className="flex w-full border-t">
                                        <div className="w-6/12 p-2">{purchase.c_company_name}</div>
                                        <div className="w-2/12 p-2 text-right"> {purchase.q_date}</div>
                                        <div className="w-2/12 p-2 text-right">{fixedNUM(purchase.qty, 0)}</div>
                                        <div className="w-2/12 p-2 text-right">{fixedNUM(purchase.inRowSumPrice)}</div>
                                    </div>
                                ))}
                                <div className="flex w-full border-t">
                                    <div className="w-6/12 p-2"></div>
                                    <div className="w-2/12 p-2 text-right">รวม</div>
                                    <div className="w-2/12 p-2 text-right">{fixedNUM(product.qty, 0)}</div>
                                    <div className="w-2/12 p-2 text-right"> {fixedNUM(product.inRowSumPrice)}</div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    )))
                        : 'ไม่พบข้อมูล'
                    }
                </CardContent>
            </Card>

            <Dialog
                maxWidth={'lg'}
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>เลือกช่วงเวลารายงาน</DialogTitle>
                <DialogContent>
                    <DateRangePicker
                        editableDateInputs={true}
                        onChange={item => setStateD([item.selection])}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        ranges={stateD}
                        color={'#3730a3'}
                        direction="horizontal"
                        locale={th}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>ยกเลิก</Button>
                    <Button onClick={() => {
                        setQueryParams(prev => ({
                            ...prev,
                            start: convertDate(stateD[0].startDate),
                            end: convertDate(stateD[0].endDate)
                        }));
                        setOpen(false);
                    }}>ตกลง</Button>
                </DialogActions>
            </Dialog>
        </>
    );

    return (
        <EmptyPage
            newMsg={newMsg}
            pageTitle="รายงาน"
            formWidth={'550px'}
            pageContent={pageContent}
        />
    )
}