import { useEffect, useState } from 'react';
import siteSetting from "../components/siteSetting"
import PageSt from "../pageSt"
import axios from 'axios';

export default function Customer({ socket }) {

    const [users, setUsers] = useState({});

    const fetchUser = (offset = 0, keyword = '') => {
        axios({
            method: 'get',
            url: siteSetting.apiUrl + `/user?offset=${offset}&keyword=${keyword}`,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
        }).then(function (response) {
            setUsers(response.data.data);
        });
    }

    useEffect(() => {
        fetchUser();
    }, []);

    const [newMsg, setNewMsg] = useState(true);

    useEffect(() => {
        socket.on("messageResponse", () => {
            localStorage.setItem('newMsg', 'true');
            setNewMsg(false);
        });
    }, [socket])

    useEffect(() => {
        if (localStorage.getItem('newMsg')) {
            setNewMsg(false);
        }
    }, [])

    return (
        <PageSt
            newMsg={newMsg}
            pageTitle="ลูกค้า"
            apiPath="/customer"
            popup={{
                popInput: [
                    {
                        id: 'name',
                        label: 'ชื่อผู้ติดต่อ',
                        type: 'text',
                        noteditable: false
                    },
                    {
                        id: 'company',
                        label: 'บริษัท',
                        type: 'text',
                        req: true,
                        noteditable: false
                    },
                    {
                        id: 'phone',
                        label: 'โทรศัพท์',
                        type: 'text',
                        noteditable: false
                    },
                    {
                        id: 'email',
                        label: 'อีเมล',
                        type: 'text',
                        noteditable: false
                    },
                    {
                        id: 'tax',
                        label: 'เลขประจำตัวผู้เสียภาษี',
                        type: 'text',
                        noteditable: false
                    },
                    {
                        id: 'branch',
                        label: 'สาขา',
                        type: 'text',
                        noteditable: false
                    },
                    {
                        id: 'address',
                        label: 'ที่อยู่',
                        type: 'text',
                        noteditable: false,
                        multiline: true,
                        rows: 4
                    },
                    {
                        id: 'owner',
                        label: 'เจ้าของ',
                        type: 'select',
                        options: users,
                        defaultValue: '1',
                        req: true,
                        noteditable: false
                    },
                ]
            }}
            formData={{
                name: '',
                company: '',
                phone: '',
                email: '',
                tax: '',
                branch: '',
                address: '',
                owner: '',
            }}
            tableData={{
                th: ['บริษัท', 'โทรศัพท์', 'อีเมล', 'เจ้าของ'],
                td: ['company', 'phone', 'email', 'ownername']
            }}
            formWidth={'550px'}
            filter={[
                {
                    urlParam: '&owner=' + localStorage.getItem('token'),
                    type: 'checkbox',
                    label: 'แสดงเฉพาะรายชื่อของฉัน'
                }
            ]}
            importButton={false}
        />
    )
}