import { useEffect, useState, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import siteSetting from "./components/siteSetting"
import SideNav from "./components/sidenav";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import axios from 'axios';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import Pagination from '@mui/material/Pagination';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
//import Avatar from '@mui/material/Avatar';
//import Menu from '@mui/material/Menu';
//import ClearAllOutlinedIcon from '@mui/icons-material/ClearAllOutlined';
//import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
//import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
//import Badge from '@mui/material/Badge';
import PageSKT from './components/tableSkt';
//import Drawer from '@mui/material/Drawer';
//import ListItem from '@mui/material/ListItem';
//import ListItemButton from '@mui/material/ListItemButton';
//import List from '@mui/material/List';
//import Divider from '@mui/material/Divider';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
//import ListItemText from '@mui/material/ListItemText';
//import Stack from '@mui/material/Stack';
//import SnackbarContent from '@mui/material/SnackbarContent';
//import AlertTitle from '@mui/material/AlertTitle';
//import Box from '@mui/material/Box';
//import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
//import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
//import Card from '@mui/material/Card';
//import CardActions from '@mui/material/CardActions';
//import CardContent from '@mui/material/CardContent';
//import Typography from '@mui/material/Typography';
import { FileUploader } from "react-drag-drop-files";
import YellowPages from './assets/images/yellow-pages.jpg';
import ExcelIcon from './assets/images/excel.png'
import CircularProgress from '@mui/material/CircularProgress';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import AppHeader from "./components/header";
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';

import { read, utils } from 'xlsx';

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function PageSt(props) {

    const [open, setOpen] = useState(false);
    const [openNoti, setOpenNoti] = useState(false);
    const [notiMsg, setNotiMsg] = useState({
        msg: '',
        type: 'error'
    });
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => { event.preventDefault(); };
    const [disabled, setDisabled] = useState(false);
    const [actionType, setActionType] = useState({
        actionBtn: 'เพิ่ม',
        actionUrl: '/addnew',
        actionMethod: 'post',
        actionSearch: ''
    });
    const [pageData, setpageData] = useState(null);
    const [openAlert, setOpenAlert] = useState(false);
    const [actionID, setActionID] = useState(null);
    const [updateFrom, setUpdateFrom] = useState(props.formData);
    const [page, setPage] = useState({
        count: 0,
        allpage: 0,
        page: 1
    });

    const handlePageChange = (event, value) => {
        fetchData(value - 1)
    };

    /*
    const [anchorEl, setAnchorEl] = useState(null);
    const openUserMenu = Boolean(anchorEl);
    const handleClickUserMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorEl(null);
    };
    */

    const navigate = useNavigate();

    /*
    const [notificationList, setNotificationList] = useState(null);
    const [openDrawer, setOpenDrawer] = useState(false);

    const handleCkickBell = () => {
        localStorage.setItem('noticount', 0);
        document.title = props.pageTitle + ' - ' + siteSetting.siteName;
        //navigate('/task');

        axios({
            method: 'get',
            url: siteSetting.apiUrl + '/noti-list',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
        }).then(function (response) {
            setNotificationList(response.data);
            setOpenDrawer(true);
        });
    }
  

    const toggleDrawer = () => {
        setOpenDrawer(false);
    }
   */
    const handleClickOpen = () => {
        setOpen(true);
    };


    const clearState = () => {
        Object.entries(updateFrom).forEach(([k, item]) => {
            let val = '';
            setUpdateFrom(prev => ({
                ...prev,
                [k]: val
            }));
        })
    }

    const handleClose = () => {
        clearState();
        setOpen(false);
    };

    const handleCloseNoti = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenNoti(false);
    };

    const fetchData = (offset = 0, keyword = '', query = '') => {
        axios({
            method: 'get',
            url: siteSetting.apiUrl + props.apiPath + actionType.actionSearch + `?offset=${offset}&keyword=${keyword}` + query,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
        }).then(function (response) {
            setpageData(response.data);
            setPage(prev => ({
                ...prev,
                count: response.data.count,
                allpage: response.data.allpage,
                page: response.data.offset + 1
            }));
        });
    }

    const submitPost = () => {

        var validate = true;
        Object.entries(props.popup.popInput).forEach(([k, item]) => {

            if (item.req === true && updateFrom[item.id] === undefined) {
                if (item.type !== 'password') {
                    setNotiMsg(prev => ({
                        ...prev,
                        msg: 'กรุณากรอกข้อมูล' + item.label,
                        type: 'error'
                    }));
                    setOpenNoti(true);
                    validate = false;
                    return;
                }
            }

            if (item.req === true && updateFrom[item.id] === '') {
                if (item.type !== 'password') {
                    setNotiMsg(prev => ({
                        ...prev,
                        msg: 'กรุณากรอกข้อมูล' + item.label,
                        type: 'error'
                    }));
                    setOpenNoti(true);
                    validate = false;
                    return;
                }
            }

            if (item.req === true && item.type === 'select' && updateFrom[item.id] === undefined) {
                setNotiMsg(prev => ({
                    ...prev,
                    msg: 'กรุณากรอกเลือก' + item.label,
                    type: 'error'
                }));
                setOpenNoti(true);
                validate = false;
                return;
            }
            if (item.req === true && item.type === 'select' && updateFrom[item.id] === '0') {
                setNotiMsg(prev => ({
                    ...prev,
                    msg: 'กรุณากรอกเลือก' + item.label,
                    type: 'error'
                }));
                setOpenNoti(true);
                validate = false;
                return;
            }
            if (item.req === true && item.type === 'select' && updateFrom[item.id] === '') {
                setNotiMsg(prev => ({
                    ...prev,
                    msg: 'กรุณากรอกเลือก' + item.label,
                    type: 'error'
                }));
                setOpenNoti(true);
                validate = false;
                return;
            }

        })

        if (validate === true) {
            setDisabled(true);
            axios({
                method: actionType.actionMethod,
                url: siteSetting.apiUrl + props.apiPath + actionType.actionUrl,
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json'
                },
                data: updateFrom
            }).then(function (response) {
                setDisabled(false);
                if (response.data.status === 500) {
                    setNotiMsg(prev => ({
                        ...prev,
                        msg: response.data.msg,
                        type: 'error'
                    }));
                    setOpenNoti(true);
                }
                if (response.data.status === 200) {
                    setNotiMsg(prev => ({
                        ...prev,
                        msg: response.data.msg,
                        type: 'success'
                    }));
                    setOpenNoti(true);
                    fetchData();
                    setOpen(false);
                    clearState();
                }
            });
        }
    }

    const deleteData = () => {
        setOpenAlert(false);
        axios({
            method: 'delete',
            url: siteSetting.apiUrl + props.apiPath + '/delete/' + actionID,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
        }).then(function (response) {
            if (response.data.status === 500) {
                setNotiMsg(prev => ({
                    ...prev,
                    msg: response.data.msg,
                    type: 'error'
                }));
                setOpenNoti(true);
            }
            if (response.data.status === 200) {
                setNotiMsg(prev => ({
                    ...prev,
                    msg: response.data.msg,
                    type: 'success'
                }));
                setOpenNoti(true);
                fetchData();
                setOpen(false);
                clearState();
            }
        });
    }

    const submitSearch = (e) => {
        e.preventDefault();
        var searchText = e.target[1].value;
        if (searchText.length < 3) {
            setNotiMsg(prev => ({
                ...prev,
                msg: 'กรุณาระบบคำค้นหามากกว่า 2 ตัวอักษร',
                type: 'error'
            }));
            setOpenNoti(true);
        } else {
            fetchData(0, searchText)
        }
    }

    const searchTextChange = (text) => {
        if (text.length === 0) {
            fetchData(0)
        }
    }

    const handleFilter = (event) => {
        if (event.target.checked) {
            fetchData(0, '', event.target.value);
        } else {
            fetchData(0, '');
        }
    };

    const convertUserRole = (role) => {
        var returnvalue;
        switch (role) {
            case '0':
                returnvalue = "-- เลือกสิทธิการใช้งาน --";
                break;
            case '1':
                returnvalue = "ผู้ดูแลระบบ";
                break;
            case '2':
                returnvalue = "ผู้จัดการผ่ายขาย";
                break;
            case '3':
                returnvalue = "พนักงานขาย";
                break;
            case '4':
                returnvalue = "ยกเลิกสิทธิการเข้าถึง";
                break;
            default: returnvalue = "-- เลือกสิทธิการใช้งาน --"
        }
        return returnvalue;
    }

    const convertType = (type) => {
        var returnvalue;
        switch (type) {
            case '0':
                returnvalue = "-- เลือกกิจกรรม --";
                break;
            case '1':
                returnvalue = "โทรศัพท์";
                break;
            case '2':
                returnvalue = "ประชุม";
                break;
            case '3':
                returnvalue = "อีเมล";
                break;
            case '4':
                returnvalue = "สิ่งที่ต้องทำ";
                break;
            case '5':
                returnvalue = "เดทไลน์";
                break;
            case '6':
                returnvalue = "อื่นๆ";
                break;
            default: returnvalue = "-- เลือกกิจกรรม --"
        }
        return returnvalue;
    }

    const convertTaskStatus = (status) => {
        var returnvalue;
        switch (status) {
            case '0':
                returnvalue = "-- เลือกสถานะ --";
                break;
            case '1':
                returnvalue = "กำลังดำเนินการ";
                break;
            case '2':
                returnvalue = "สำเร็จ";
                break;
            default: returnvalue = "-- เลือกสถานะ --"
        }
        return returnvalue;
    }

    useEffect(() => {
        document.title = props.pageTitle + ' - ' + siteSetting.siteName;

        if (props.apiPath) {
            axios({
                method: 'get',
                url: siteSetting.apiUrl + props.apiPath + '?offset=0',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json'
                },
            }).then(function (response) {
                if (response.data.status === 500 && response.data.msg === 'รูปแบบ Token ไม่ถูกต้อง') {
                    navigate('/logout')
                }
                setpageData(response.data);
                setPage(prev => ({
                    ...prev,
                    count: response.data.count,
                    allpage: response.data.allpage,
                    page: response.data.offset + 1
                }));
            });
        }

    }, [props, navigate])

    var disables = false;
    var formWidth = '300px'
    if (props.formWidth) {
        formWidth = props.formWidth;
    }

    const [notiCount, setNotiCount] = useState(parseInt(localStorage.getItem('noticount')));

    useEffect(() => {
        const interval = setInterval(() => {
            if (parseInt(localStorage.getItem('noticount')) !== notiCount) {
                setNotiCount(parseInt(localStorage.getItem('noticount')))
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [notiCount]);

    const [openImport, setOpenImport] = useState(false);
    const [openYellow, setOpenYellow] = useState(false);
    const [yellowPages, setYellowPages] = useState({
        url: '',
        owner: '',
        group: ''
    });
    const [yellowPagesErr, setYellowPagesErr] = useState({
        url: false,
        owner: false,
        group: false
    });

    const sendDataYellow = () => {

        if (!yellowPages.url.includes('https://www.yellowpages.co.th/heading/')) {
            setNotiMsg(prev => ({
                ...prev,
                msg: 'รูปแบบลิ้งก์ไม่ถูกต้อง',
                type: 'error'
            }));
            setOpenNoti(true);
            setYellowPagesErr(prev => ({
                ...prev,
                url: true,
            }));
            return;
        }
        if (yellowPages.owner === '') {
            setNotiMsg(prev => ({
                ...prev,
                msg: 'กรุณาเลือกเจ้าของรายชื่อ',
                type: 'error'
            }));
            setOpenNoti(true);
            setYellowPagesErr(prev => ({
                ...prev,
                owner: true,
            }));
            return;
        }
        if (yellowPages.group === '') {
            setNotiMsg(prev => ({
                ...prev,
                msg: 'กรุณาเลือกกลุ่มรายชื่อ',
                type: 'error'
            }));
            setOpenNoti(true);
            setYellowPagesErr(prev => ({
                ...prev,
                group: true,
            }));
            return;
        }

        setDisabled(true);
        axios({
            method: 'post',
            url: 'https://yellowpages.iapi.8content.com/lead/scrape',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                url: yellowPages.url,
                owner: yellowPages.owner,
                group: yellowPages.group,
                key: "UANgRJQW27UBFnGwgG2Jfqucm2RonG"
            },
            timeout: 60000,
        }).then(function (response) {
            setYellowPagesErr(prev => ({
                ...prev,
                url: false,
                owner: false,
                group: false
            }));
            setDisabled(false);
            if (response.data.data.status === 500) {
                setNotiMsg(prev => ({
                    ...prev,
                    msg: response.data.data.msg,
                    type: 'error'
                }));
                setOpenNoti(true);
            }
            if (response.data.data.status === 200) {
                setNotiMsg(prev => ({
                    ...prev,
                    msg: response.data.data.msg,
                    type: 'success'
                }));
                setOpenNoti(true);
                setOpenYellow(false);
                fetchData();
            }
        }).catch(error => {
            setDisabled(false);
            if (error.code === 'ECONNABORTED' || error.code === 'ERR_NETWORK') {
                setNotiMsg(prev => ({
                    ...prev,
                    msg: 'ไม่สามารถโหลดหน้าเว็บที่ระบุในเวลาที่กำหนดได้',
                    type: 'error'
                }));
                setOpenNoti(true)
            } else {
                throw error;
            }

        });
    }

    const [openExcel, setOpenExcel] = useState(false);
    const fileTypes = ["CSV", "XLSX"];
    const [file, setFile] = useState(null);
    const [pres, setPres] = useState([]);
    const handleChangeExcel = async (file) => {
        setFile(file);
        const xfile = await file.arrayBuffer();
        const wb = read(xfile);
        const ws = wb.Sheets[wb.SheetNames[0]]; // get the first worksheet
        const data = utils.sheet_to_json(ws); // generate objects
        setPres(data);
    };

    const uploadExcel = () => {
        if (yellowPages.owner === '') {
            setNotiMsg(prev => ({
                ...prev,
                msg: 'กรุณาเลือกเจ้าของรายชื่อ',
                type: 'error'
            }));
            setOpenNoti(true);
            setYellowPagesErr(prev => ({
                ...prev,
                owner: true,
            }));
            return;
        }
        if (yellowPages.group === '') {
            setNotiMsg(prev => ({
                ...prev,
                msg: 'กรุณาเลือกกลุ่มรายชื่อ',
                type: 'error'
            }));
            setOpenNoti(true);
            setYellowPagesErr(prev => ({
                ...prev,
                group: true,
            }));
            return;
        }

        setDisabled(true);
        axios({
            method: 'post',
            url: `${siteSetting.apiUrl}/manual-insert?key=Gt8aI2nCmEAqpYYHs73v97OlVy82x3`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                data: pres,
                owner: yellowPages.owner,
                group: yellowPages.group,
            }
        }).then(function (response) {
            setDisabled(false);
            if (response.data.status === 500) {
                setNotiMsg(prev => ({
                    ...prev,
                    msg: response.data.msg,
                    type: 'error'
                }));
                setOpenNoti(true);
            }
            if (response.data.status === 200) {
                setNotiMsg(prev => ({
                    ...prev,
                    msg: response.data.msg,
                    type: 'success'
                }));
                setOpenNoti(true);
                setOpenYellow(false);
                fetchData();
            }
        })
    }

    const convertToPill = (text, color, bg) => {
        return <span className={`px-4 py-1 text-xs whitespace-nowrap rounded-full ${color} ${bg}`}>{text}</span>
    }



    return (
        <>
            <div className="app-layout-modern flex flex-auto flex-col">
                <div className="flex flex-auto min-w-0">
                    <SideNav />
                    <div className="flex flex-col flex-auto min-h-screen min-w-0 relative w-full bg-main-bg-color justify-between max-h-screen bg-slate-50">
                        <AppHeader
                            pageTitle={props.pageTitle}
                            disableFrom={props.disableFrom}
                            submitSearch={submitSearch}
                            searchTextChange={searchTextChange}
                            notiCount={notiCount}
                            newMsg={props.newMsg}
                        />
                        <div className="relative overflow-auto px-7 py-5 mb-auto " id="main-content">
                            {!props.carlendar &&
                                <div className='flex items-center justify-between'>
                                    <div className='flex gap-4 items-center'>
                                        <h1 className='text-xl font-bold'>{props.pageTitle}</h1>
                                        {props.filter && Object.entries(props.filter).map(([k, item]) =>
                                            item.type === 'checkbox' && (
                                                <FormGroup key={k} style={{ background: '#f3f3f3', padding: '0em 2em 0em 1em', borderRadius: '99px', marginLeft: '1em' }}>
                                                    <FormControlLabel control={<Checkbox value={item.urlParam} onChange={handleFilter} />} label={item.label} />
                                                </FormGroup>
                                            )
                                        )}
                                    </div>

                                    {props.pageTitle !== 'Dashboard' &&
                                        <div className='flex gap-2'>
                                            {props.importButton &&
                                                <Button variant="outlined" className='flex gap-2 items-center' style={{ alignItems: 'center' }}
                                                    onClick={() => {
                                                        /*
                                                        setNotiMsg(prev => ({
                                                            ...prev,
                                                            msg: 'การนำเข้าข้อมูลถูกปิดใช้งาน กรุณาติดต่อผู้ดูแลระบบ',
                                                            type: 'error'
                                                        }));
                                                        setOpenNoti(true);
                                                        */
                                                        setOpenImport(true);
                                                    }}>
                                                    <FileUploadOutlinedIcon />
                                                    Import
                                                </Button>
                                            }
                                            <Button variant="contained" className='flex gap-2 items-center' style={{ alignItems: 'center' }}
                                                onClick={() => {
                                                    handleClickOpen();
                                                    setActionType(prev => ({
                                                        ...prev,
                                                        actionBtn: 'เพิ่ม',
                                                        actionUrl: '/addnew',
                                                        actionMethod: 'post'
                                                    }));
                                                }}>
                                                <AddOutlinedIcon />
                                                Add new
                                            </Button>
                                        </div>
                                    }

                                </div>
                            }
                            <div className='table-responsive my-7 min-h-80 bg-white'>

                                {/*props.pageTitle === 'Dashboard' &&
                                    <div className='w-1/3'>
                                        <Card sx={{ minWidth: 275 }}>
                                            <CardContent>
                                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                    สิ่งที่ต้องทำวันนี้
                                                </Typography>
                                                <Typography variant="h5" component="div">
                                                    {pageData ? pageData.task : 0} รายการ
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                                <Button size="small" onClick={() => navigate('/task')}>จัดการ</Button>
                                            </CardActions>
                                        </Card>
                                    </div>
                        */}

                                {props.tableData &&
                                    <table className="table-auto w-full border-collapse border border-slate-150">
                                        <thead>
                                            <tr>
                                                {Object.entries(props.tableData.th).map(([k, item]) => (
                                                    <th key={k} className='text-left border-b border-slate-150 p-4 pl-8 pt-3 pb-3 font-bold'>{item}</th>
                                                ))}
                                                <th className='text-right border-b border-slate-150 p-4 pl-8 pt-3 pb-3 font-bold'>จัดการ</th>
                                            </tr>
                                        </thead>
                                        <tbody id='mainTable' className="border-t-2 border-slate-150">
                                            {page.count > 0 && pageData !== null && Object.entries(pageData.data).map(([kk, itms]) => (
                                                <tr key={kk}>
                                                    {Object.entries(props.tableData.td).map(([kkk, itm]) => (
                                                        <td key={kkk} className='border-b p-4 pl-8 border-slate-150'>
                                                            {props.apiPath === "/user" && itm === 'role' && convertUserRole(itms[itm])}
                                                            {props.apiPath === "/task" && itm === 'type' && convertType(itms[itm])}
                                                            {props.apiPath === "/task" && itm === 'status' && convertTaskStatus(itms[itm])}
                                                            {props.statusOption && props.apiPath !== "/task" && itm === 'status' &&
                                                                convertToPill(props.statusOption[itms.status - 1].label, props.statusOption[itms.status - 1].color, props.statusOption[itms.status - 1].bg)}
                                                            {itm !== 'role' && itm !== 'type' && itm !== 'status' && itms[itm]}
                                                        </td>
                                                    ))}
                                                    <td className='border-b p-4 border-slate-150 flex items-center space-x-1 justify-end'>
                                                        {props.apiPath === "/product" &&
                                                            <button type="button" className='py-0 px-2'
                                                                onClick={() => {
                                                                    navigate(`/product/${itms.id}`)
                                                                }}>
                                                                <Inventory2OutlinedIcon />
                                                            </button>
                                                        }
                                                        <button type="button" className='py-0 px-2'
                                                            onClick={() => {
                                                                setActionType(prev => ({
                                                                    ...prev,
                                                                    actionBtn: 'แก้ไข',
                                                                    actionUrl: '/update/' + itms.id,
                                                                    actionMethod: 'put'
                                                                }));
                                                                handleClickOpen();
                                                                setActionID(itms.id);
                                                                setUpdateFrom(pageData.data[kk]);
                                                            }}>
                                                            <CreateOutlinedIcon />
                                                        </button>
                                                        <button type="button" className='py-0 px-2'
                                                            onClick={() => {
                                                                setOpenAlert(true);
                                                                setActionID(itms.id);
                                                            }}>
                                                            <DeleteForeverOutlinedIcon />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                            {page.count === '0' &&
                                                <tr>
                                                    <td className='p-4'>ยังไม่มีรายการ</td>
                                                </tr>
                                            }
                                            {pageData === null &&
                                                <PageSKT tr='20' td='5' />
                                            }
                                        </tbody>
                                    </table>
                                }

                                {props.carlendar && <div style={{ maxWidth: '100%' }}>{props.carlendar}</div>}
                            </div>
                        </div>
                        {props.pageTitle !== 'Dashboard' &&
                            <div className='px-7 py-3 sticky bottom-0  flex justify-between'>
                                {!props.carlendar && <><div>ทั้งหมด {page.count} รายการ</div>
                                    <Pagination count={page.allpage} page={page.page} onChange={handlePageChange} onClick={() => { document.getElementById('main-content').scrollTo({ top: 0, behavior: 'smooth' }); }} /></>}
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{actionType.actionBtn + props.pageTitle}</DialogTitle>
                <DialogContent>
                    {props.popup && Object.entries(props.popup.popInput).map(([k, item]) => (
                        <div key={k} className='mb-4' style={{ minWidth: formWidth }}>
                            {actionType.actionMethod === 'put' && item.noteditable === true ? disables = true : disables = false}
                            {item.type === 'password' &&
                                <FormControl key={k} variant="standard" fullWidth>
                                    <InputLabel htmlFor={item.id}>{item.label}</InputLabel>
                                    <Input
                                        id={item.id}
                                        type={showPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label={item.label}
                                        fullWidth
                                        variant="standard"
                                        onChange={(e) => {
                                            //updatedState[item.id] = e.target.value;
                                            let val = e.target.value;
                                            setUpdateFrom(prev => ({
                                                ...prev,
                                                [item.id]: val
                                            }));
                                        }}
                                    />
                                </FormControl>
                            }
                            {item.type === 'select' &&
                                <TextField
                                    key={k}
                                    id={item.id}
                                    label={item.label}
                                    select
                                    defaultValue={item.defaultValue}
                                    fullWidth
                                    variant="standard"
                                    value={updateFrom[item.id] || ''}
                                    onChange={(e) => {
                                        let val = e.target.value;
                                        setUpdateFrom(prev => ({
                                            ...prev,
                                            [item.id]: val
                                        }));
                                    }}
                                >
                                    {Object.entries(item.options).map(([kk, option]) => (
                                        <MenuItem
                                            key={kk}
                                            value={option.value || option.id || option.status - 1}
                                            onClick={() => {
                                                //updatedState[item.id] = option.value;
                                                //let val = option.value;
                                                //setUpdateFrom(prev => ({
                                                //    ...prev,
                                                //    [item.id]: val
                                                //}));
                                            }}
                                        >
                                            {option.label || option.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            }
                            {item.type === 'dateTime' &&
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker
                                        renderInput={(props) => <TextField {...props} variant="standard" fullWidth />}
                                        ampm={false}
                                        label={item.label}
                                        inputFormat="YYYY/MM/DD HH:mm"
                                        value={updateFrom[item.id] || dayjs(new Date()).format('YYYY/MM/DD HH:mm')}
                                        onChange={(newValue) => {
                                            if (newValue.$d !== 'Invalid Date') {
                                                setUpdateFrom(prev => ({
                                                    ...prev,
                                                    [item.id]: dayjs(newValue.$d).format('YYYY/MM/DD HH:mm')
                                                }));
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                            }
                            {item.type !== 'select' && item.type !== 'password' && item.type !== 'dateTime' &&
                                <TextField
                                    key={k}
                                    margin="dense"
                                    id={item.id}
                                    label={item.label}
                                    type={item.type}
                                    fullWidth
                                    variant="standard"
                                    onChange={(e) => {
                                        //updatedState[item.id] = e.target.value;
                                        let val = e.target.value;
                                        setUpdateFrom(prev => ({
                                            ...prev,
                                            [item.id]: val
                                        }));
                                    }}
                                    value={updateFrom[item.id] || ''}
                                    disabled={disables}
                                    multiline={item.multiline}
                                    rows={item.rows}
                                />
                            }
                        </div>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>ยกเลิก</Button>
                    <Button onClick={submitPost} disabled={disabled}>{actionType.actionBtn + props.pageTitle}</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openAlert}
                onClose={() => setOpenAlert(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"ยืนยันการลบข้อมูล"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        หากยืนยัน ข้อมูลจะถูกลบและไม่สามารถกู้คืนได้
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenAlert(false)}>ยกเลิก</Button>
                    <Button onClick={() => deleteData()} autoFocus>ยืนยัน</Button>
                </DialogActions>
            </Dialog>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={openNoti} autoHideDuration={6000} onClose={handleCloseNoti}>
                <Alert onClose={handleCloseNoti} severity={notiMsg.type} sx={{ width: '100%' }}>
                    {notiMsg.msg}
                </Alert>
            </Snackbar>



            {props.importscrape && props.importExcel &&
                <>
                    <Dialog
                        open={openImport}
                        onClose={() => setOpenImport(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title-2" className='text-center font-bold'>เลือกรูปแบบการนำเข้าข้อมูล</DialogTitle>

                        <DialogContent>
                            <div className='flex gap-8 p-8'>
                                <div className='w-1/2 p-4 text-center border-2 border-dashed rounded-xl border-gray-200 cursor-pointer'
                                    onClick={() => {
                                        setOpenExcel(true);
                                        setOpenImport(false);
                                    }}>
                                    <div className='flex items-center justify-center p-4'>
                                        <img src={ExcelIcon} className='max-w-full' alt='excel' />
                                    </div>
                                    <h4 className='text-md'>Upload ไฟล์ Excel</h4>
                                </div>
                                <div className='w-1/2 p-4 text-center border-2 border-dashed rounded-xl border-gray-200 cursor-pointer'
                                    onClick={() => {
                                        setOpenYellow(true);
                                        setOpenImport(false);
                                    }}>
                                    <div className='flex items-center justify-center p-4'>
                                        <img src={YellowPages} className='max-w-full' alt='yellow pages' />
                                    </div>
                                    <h4 className='text-md'>ดึงข้อมูลจาก Yellow pages</h4>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>

                    <Dialog
                        open={openYellow}
                        onClose={() => setOpenYellow(false)}
                        aria-labelledby="alert-dialog-title-3"
                        aria-describedby="alert-dialog-description-3"
                    >
                        <DialogTitle id="alert-dialog-title-3">ดึงข้อมูลจาก Yellow pages</DialogTitle>
                        <DialogContent>
                            <p className='mb-4'>กรอกข้อมูล URL <a className='underline' href="https://www.yellowpages.co.th/category" target="_blank" rel="noreferrer">ของหน้ารายชื่อบริษัท</a> ตัวอย่างเช่น https://www.yellowpages.co.th/heading/อุปกรณ์และเครื่องใช้อุตสาหกรรม <br />กรณีมีหลายหน้า ให้ดึงข้อมูลทีละหน้า</p>
                            <div className='mb-4' style={{ minWidth: formWidth }}>
                                <TextField
                                    error={yellowPagesErr.url}
                                    helperText={yellowPagesErr.url ? 'รูปแบบลิ้งก์ไม่ถูกต้อง' : ''}
                                    margin="dense"
                                    label='ลิ้งก์หน้า Yellow Pages'
                                    placeholder='เช่น https://www.yellowpages.co.th/heading/เครื่องจักรกล'
                                    type='text'
                                    fullWidth
                                    variant="standard"
                                    onChange={(e) => {
                                        if (e.target.value === '' || e.target.value === undefined) {
                                            setYellowPagesErr(prev => ({
                                                ...prev,
                                                url: true,
                                            }));
                                        }
                                        setYellowPages(prev => ({
                                            ...prev,
                                            url: e.target.value,
                                        }));
                                        setYellowPagesErr(prev => ({
                                            ...prev,
                                            url: false,
                                        }));
                                    }}
                                />
                            </div>
                            <div className='mb-4' style={{ minWidth: formWidth }}>
                                <TextField
                                    error={yellowPagesErr.owner}
                                    helperText={yellowPagesErr.owner ? "กรุณาเลือกเจ้าของรายชื่อ" : ''}
                                    label='เจ้าของ'
                                    select
                                    fullWidth
                                    variant="standard"
                                    defaultValue={''}
                                    onChange={(e) => {
                                        let val = e.target.value;
                                        if (val === '' || val === undefined) {
                                            setYellowPagesErr(prev => ({
                                                ...prev,
                                                owner: true,
                                            }));
                                        }
                                        setYellowPages(prev => ({
                                            ...prev,
                                            owner: val,
                                        }));
                                        setYellowPagesErr(prev => ({
                                            ...prev,
                                            owner: false,
                                        }));
                                    }}
                                >
                                    {Object.entries(props.dataOwner).map(([kk, option]) => (
                                        <MenuItem key={kk} value={option.id}> {option.name} </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                            <div className='mb-4' style={{ minWidth: formWidth }}>
                                <TextField
                                    error={yellowPagesErr.group}
                                    helperText={yellowPagesErr.group ? "กรุณาเลือกกลุ่มรายชื่อ" : ''}
                                    label='กลุ่มรายชื่อ'
                                    select
                                    fullWidth
                                    variant="standard"
                                    defaultValue={''}
                                    onChange={(e) => {
                                        let val = e.target.value;
                                        if (val === '' || val === undefined) {
                                            setYellowPagesErr(prev => ({
                                                ...prev,
                                                group: true,
                                            }));
                                        }
                                        setYellowPages(prev => ({
                                            ...prev,
                                            group: val,
                                        }));
                                        setYellowPagesErr(prev => ({
                                            ...prev,
                                            group: false
                                        }));
                                    }}
                                >
                                    {Object.entries(props.dataLeadGroup).map(([kk, option]) => (
                                        <MenuItem key={kk} value={option.id}> {option.name} </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenYellow(false)}>ยกเลิก</Button>
                            <Button onClick={() => sendDataYellow()} disabled={disabled}>
                                ดึงข้อมูล
                                {disabled && <CircularProgress size="1rem" />}
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={openExcel}
                        onClose={() => setOpenExcel(false)}
                        aria-labelledby="alert-dialog-title-4"
                        aria-describedby="alert-dialog-description-4"
                    >
                        <DialogTitle id="alert-dialog-title-4" className='text-left font-bold'>
                            Upload ไฟล์ Excel
                            <a className='underline ml-2' href="/docfile/pres.xlsx" target="_blank" rel="nofollow">ดาวน์โหลดตัวอย่างไฟล์</a>
                        </DialogTitle>
                        <DialogContent>

                            <div className='mb-4 iupload-warp relative' style={{ minWidth: formWidth }}>
                                <FileUploader
                                    label="ลากวาง หรือคลิก Upload ไฟล์ Excel"
                                    hoverTitle="วางไฟล์ที่นี่"
                                    maxSize={5}
                                    multiple={false}
                                    handleChange={handleChangeExcel}
                                    name="file"
                                    types={fileTypes}
                                />
                                <p className='text-center'>{file ? `File name: ${file.name}` : ""}</p>
                            </div>
                            <div className='mb-4' style={{ minWidth: formWidth }}>
                                <TextField
                                    error={yellowPagesErr.owner}
                                    helperText={yellowPagesErr.owner ? "กรุณาเลือกเจ้าของรายชื่อ" : ''}
                                    label='เจ้าของ'
                                    select
                                    fullWidth
                                    variant="standard"
                                    defaultValue={''}
                                    onChange={(e) => {
                                        let val = e.target.value;
                                        if (val === '' || val === undefined) {
                                            setYellowPagesErr(prev => ({
                                                ...prev,
                                                owner: true,
                                            }));
                                        }
                                        setYellowPages(prev => ({
                                            ...prev,
                                            owner: val,
                                        }));
                                        setYellowPagesErr(prev => ({
                                            ...prev,
                                            owner: false,
                                        }));
                                    }}
                                >
                                    {Object.entries(props.dataOwner).map(([kk, option]) => (
                                        <MenuItem key={kk} value={option.id}> {option.name} </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                            <div className='mb-4' style={{ minWidth: formWidth }}>
                                <TextField
                                    error={yellowPagesErr.group}
                                    helperText={yellowPagesErr.group ? "กรุณาเลือกกลุ่มรายชื่อ" : ''}
                                    label='กลุ่มรายชื่อ'
                                    select
                                    fullWidth
                                    variant="standard"
                                    defaultValue={''}
                                    onChange={(e) => {
                                        let val = e.target.value;
                                        if (val === '' || val === undefined) {
                                            setYellowPagesErr(prev => ({
                                                ...prev,
                                                group: true,
                                            }));
                                        }
                                        setYellowPages(prev => ({
                                            ...prev,
                                            group: val,
                                        }));
                                        setYellowPagesErr(prev => ({
                                            ...prev,
                                            group: false
                                        }));
                                    }}
                                >
                                    {Object.entries(props.dataLeadGroup).map(([kk, option]) => (
                                        <MenuItem key={kk} value={option.id}> {option.name} </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenExcel(false)}>ยกเลิก</Button>
                            <Button onClick={() => uploadExcel()} disabled={disabled}>
                                Upload ข้อมูล
                                {disabled && <CircularProgress size="1rem" />}
                            </Button>
                        </DialogActions>
                    </Dialog>

                </>
            }

        </>
    )
}