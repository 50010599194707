import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios';
import siteSetting from "../components/siteSetting"

const ChatBody = ({ messages, typingStatus, lastMessageRef }) => {

  const [oldMsg, setOldMsg] = useState({
    offset: 0,
    all: 1,
    data: null
  });

  const msgPage = useRef(1);

  useEffect(() => {
    axios({
      method: 'get',
      url: siteSetting.apiUrl + '/chat?offset=0',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/json'
      },
    }).then(function (response) {
      setOldMsg(prev => ({
        ...prev,
        offset: response.data.offset,
        all: response.data.allpage,
        data: response.data.data
      }));
    });
  }, [])

  useEffect(() => {
    if (msgPage.current === 1) {
      let scroll_to_bottom = document.getElementById('chat-warp');
      scroll_to_bottom.scrollTop = scroll_to_bottom.scrollHeight;
    }
  }, [oldMsg])

  useEffect(() => {
    document.getElementById('chat-warp').addEventListener('scroll', () => {
      if (document.getElementById('chat-warp').scrollTop === 0) {

        if (msgPage.current < oldMsg.all) {
          axios({
            method: 'get',
            url: siteSetting.apiUrl + '/chat?offset=' + oldMsg.offset + 1,
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('token'),
              'Content-Type': 'application/json'
            },
          }).then(function (response) {
            msgPage.current = response.data.offset + 1;
            setOldMsg(prev => ({
              ...prev,
              offset: response.data.offset,
              all: response.data.allpage,
              data: [...response.data.data, ...prev.data]
            }));
          });
        }
      }
    });
  }, [oldMsg.offset, oldMsg.all]);

  return (
    <>
      <div className='message__container w-full'>

        {oldMsg.data !== null && Object.entries(oldMsg.data).map(([k, item]) => (
          item.user === localStorage.getItem("user") ? (
            <div className="message__chats" key={item.time + k}>
              <p className='sender__name text-right block mt-2 mb-0.5'>คุณ: </p>
              <div className='message__sender text-right '>
                <span className='bg-slate-200 py-2 px-4 rounded inline-block max-w-screen-sm text-left'>{item.msg}</span>
              </div>
            </div>
          ) : (
            <div className="message__chats" key={item.time + k}>
              <p className='block mt-2 mb-0.5'>{item.name}: </p>
              <div className='message__recipient'>
                <span className='bg-indigo-800 py-2 px-4 rounded inline-block max-w-screen-sm text-white'>{item.msg}</span>
              </div>
            </div>
          )
        ))}

        {messages.map(message => (
          message.name === localStorage.getItem("name") ? (
            <div className="message__chats" key={message.id}>
              <p className='sender__name text-right block mt-2 mb-0.5'>คุณ: </p>
              <div className='message__sender text-right '>
                <span className='bg-slate-200 py-2 px-4 rounded inline-block max-w-screen-sm text-left'>{message.text}</span>
              </div>
            </div>
          ) : (
            <div className="message__chats" key={message.id}>
              <p className='block mt-2 mb-0.5'>{message.name}: </p>
              <div className='message__recipient'>
                <span className='bg-indigo-800 py-2 px-4 rounded inline-block max-w-screen-sm text-white'>{message.text}</span>
              </div>
            </div>
          )
        ))}
        <div className='message__status'>
          <p>{typingStatus}</p>
        </div>
        <div ref={lastMessageRef} />
      </div>
    </>
  )
}

export default ChatBody