import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import siteSetting from "./components/siteSetting"
import SideNav from "./components/sidenav";
import AppHeader from "./components/header";

export default function EmptyPage(props) {
    const navigate = useNavigate();
    useEffect(() => {
        document.title = props.pageTitle + ' - ' + siteSetting.siteName;

    }, [props, navigate])


    const [notiCount, setNotiCount] = useState(parseInt(localStorage.getItem('noticount')));

    useEffect(() => {
        const interval = setInterval(() => {
            if (parseInt(localStorage.getItem('noticount')) !== notiCount) {
                setNotiCount(parseInt(localStorage.getItem('noticount')))
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [notiCount]);

    return (
        <>
            <div className="app-layout-modern flex flex-auto flex-col">
                <div className="flex flex-auto min-w-0">
                    <SideNav />
                    <div className="flex flex-col flex-auto min-h-screen min-w-0 relative w-full bg-main-bg-color justify-between max-h-screen">
                        <AppHeader
                            pageTitle={props.pageTitle}
                            disableFrom={true}
                            notiCount={notiCount}
                            newMsg={props.newMsg}
                        />
                        <div className="relative overflow-auto px-7 py-5 mb-auto bg-slate-50 h-screen" id="main-content">
                            <div className='table-responsive py-7 min-h-80'>
                                {props.pageContent}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}