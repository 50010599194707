import PageSt from "../pageSt";
import dayjs from "dayjs";
import { useEffect, useState } from 'react';
export default function Task({ socket }) {
  const [newMsg, setNewMsg] = useState(true);

  useEffect(() => {
    socket.on("messageResponse", () => {
      localStorage.setItem('newMsg', 'true');
      setNewMsg(false);
    });
  }, [socket])

  useEffect(() => {
    if (localStorage.getItem('newMsg')) {
      setNewMsg(false);
    }
  }, [])
  return (
    <>
      <PageSt
        newMsg={newMsg}
        pageTitle="สิ่งที่ต้องทำ"
        apiPath="/task"
        popup={{
          popInput: [
            {
              id: "type",
              label: "กิจกรรม",
              type: "select",
              options: [
                {
                  value: "0",
                  label: "-- เลือกกิจกรรม --",
                },
                {
                  value: "1",
                  label: "โทรศัพท์",
                },
                {
                  value: "2",
                  label: "ประชุม",
                },
                {
                  value: "3",
                  label: "อีเมล",
                },
                {
                  value: "4",
                  label: "สิ่งที่ต้องทำ",
                },
                {
                  value: "5",
                  label: "เดทไลน์",
                },
                {
                  value: "6",
                  label: "อื่นๆ",
                },
              ],
              defaultValue: "0",
              req: true,
              noteditable: false,
            },
            {
              id: "title",
              label: "ชื่อกิจกรรม",
              type: "text",
              req: true,
              noteditable: false,
              multiline: false,
            },
            {
              id: "desc",
              label: "รายละเอียด",
              type: "text",
              noteditable: false,
              multiline: true,
              rows: 4,
            },
            {
              id: "start",
              label: "เวลาเริ่มต้นงาน",
              type: "dateTime",
              req: true,
              noteditable: false,
              multiline: false,
              time: dayjs(new Date()).format("YYYY/MM/DD HH:mm"),
            },
            {
              id: "end",
              label: "เวลาสิ้นสุด",
              type: "dateTime",
              req: true,
              noteditable: false,
              multiline: false,
              time: dayjs(new Date()).format("YYYY/MM/DD HH:mm"),
            },
            {
              id: "notify",
              label: "การแจ้งเตือน",
              type: "select",
              options: [
                {
                  value: "0",
                  label: "-- เลือกการแจ้งเตือน --",
                },
                {
                  value: "1",
                  label: "ไม่แจ้งเตือน",
                },
                {
                  value: "2",
                  label: "เตือนล่วงหน้า 15 นาที",
                },
                {
                  value: "3",
                  label: "เตือนล่วงหน้า 1 ชั่วโมง",
                },
                {
                  value: "4",
                  label: "เตือนล่วงหน้า 1 วัน",
                },
              ],
              defaultValue: "0",
              req: true,
              noteditable: false,
            },
            {
              id: "status",
              label: "สถานะ",
              type: "select",
              options: [
                {
                  value: "0",
                  label: "-- เลือกสถานะ --",
                },
                {
                  value: "1",
                  label: "กำลังดำเนินการ",
                },
                {
                  value: "2",
                  label: "สำเร็จ",
                },
              ],
              defaultValue: "0",
              req: true,
              noteditable: false,
            },
          ],
        }}
        formData={{
          type: "",
          title: "",
          desc: "",
          start: dayjs(new Date()).format("YYYY/MM/DD HH:mm"),
          end: dayjs(new Date()).format("YYYY/MM/DD HH:mm"),
          notify: "",
          status: "",
        }}
        tableData={{
          th: ["ประเภท", "หัวข้อ", "เริ่มต้น", "สิ้นสุด", "สถานะ"],
          td: ["type", "title", "start", "end", "status"],
        }}
        formWidth={"550px"}
        importButton={false}
      />
    </>
  );
}
