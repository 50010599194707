import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import notiSound from "./assets/sounds/mixkit-long-pop-2358.wav";
import PrivateRoute from "./components/useAuth";
import Login from "./pages/login";
import Dashboard from "./pages/dashboard";
import Notfound from "./pages/notfound";
import User from "./pages/users";
import Leads from "./pages/leads";
import LeadsGroup from "./pages/leadGroup";
import Task from "./pages/task";
import Customer from "./pages/customer";
import Logout from "./pages/logout";
import Pusher from "pusher-js";
import toast, { Toaster } from "react-hot-toast";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import ChatPage from "./pages/chat";
import Carlendar from "./pages/carlendar";
import socketIO from "socket.io-client";
import QuotationList from "./pages/quotation";
import Product from "./pages/products";
import Invoice from "./pages/invoice";
import Receipt from "./pages/receipt";
import StockManagement from "./pages/stock";
import UpdateLog from "./pages/updateLog";
import Report from "./pages/report";
import ReportProduct from "./pages/reportProduct"
import ReportCustomer from "./pages/reportCustomer"
import PurchaseOrders from "./pages/purchaseOrders"
import Purchases from "./pages/purchases"
import Expenses from "./pages/expenses"

const socket = socketIO.connect("https://chat.iapi.8content.com");

const theme = createTheme({
  typography: {
    fontFamily: ["Noto Sans Thai"].join(","),
  },
  palette: {
    primary: {
      light: "#6e5ad5",
      main: "#3730a3",
      dark: "#000973",
      contrastText: "#fff",
    },
    secondary: {
      light: "#fff154",
      main: "#febf12",
      dark: "#c68f00",
      contrastText: "#000",
    },
  },
});

function App() {
  const notify = (data) =>
    toast.custom((t) => (
      <div
        className={`${t.visible ? "animate-enter" : "animate-leave"
          } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
      >
        <div className="flex-1 w-0 p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0 pt-0.5">
              <NotificationsActiveOutlinedIcon />
            </div>
            <div className="ml-3 flex-1">
              <p className="text-sm font-medium text-gray-900">{data.title}</p>
              <p className="mt-1 text-sm text-gray-500">
                {data.start && data.start && `วัน - เวลา: ${data.start} - ${data.end}`}
                {data.description && data.description}
              </p>
            </div>
          </div>
        </div>
        <div className="flex border-l border-gray-200">
          <button
            onClick={() => {
              toast.dismiss(t.id);
              document.getElementById("top-notification").click();
            }}
            className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          >
            ปิด
          </button>
        </div>
      </div>
    ));

  //Pusher.logToConsole = true;
  const pusher = new Pusher("fa172902837a9044d8b3", {
    cluster: "ap1",
  });
  const channel = pusher.subscribe("votes-" + localStorage.getItem("user"));
  channel.bind("vote - event", function (data) {
    const setlastCount = parseInt(localStorage.getItem("noticount")) + 1;
    localStorage.setItem("noticount", setlastCount);
    notify(data);
    var audio = new Audio(notiSound);
    audio.play();
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route
              index
              element={
                <PrivateRoute>
                  <Dashboard socket={socket} />
                </PrivateRoute>
              }
            />

            <Route
              path="/user"
              element={
                <PrivateRoute>
                  <User socket={socket} />
                </PrivateRoute>
              }
            />
            <Route
              path="/leads"
              element={
                <PrivateRoute>
                  <Leads socket={socket} />
                </PrivateRoute>
              }
            />
            <Route
              path="/lead-groups"
              element={
                <PrivateRoute>
                  <LeadsGroup socket={socket} />
                </PrivateRoute>
              }
            />
            <Route
              path="/task"
              element={
                <PrivateRoute>
                  <Task socket={socket} />
                </PrivateRoute>
              }
            />
            <Route
              path="/customer"
              element={
                <PrivateRoute>
                  <Customer socket={socket} />
                </PrivateRoute>
              }
            />

            <Route
              path="/carlendar"
              element={
                <PrivateRoute>
                  <Carlendar socket={socket} />
                </PrivateRoute>
              }
            />

            <Route
              path="/quotations"
              element={
                <PrivateRoute>
                  <QuotationList socket={socket} />
                </PrivateRoute>
              }
            />

            <Route
              path="/products"
              element={
                <PrivateRoute>
                  <Product socket={socket} />
                </PrivateRoute>
              }
            />

            <Route
              path="/product/:id"
              element={
                <PrivateRoute>
                  <StockManagement socket={socket} />
                </PrivateRoute>
              }
            />

            <Route
              path="/invoice"
              element={
                <PrivateRoute>
                  <Invoice socket={socket} />
                </PrivateRoute>
              }
            />

            <Route
              path="/receipt"
              element={
                <PrivateRoute>
                  <Receipt socket={socket} />
                </PrivateRoute>
              }
            />

            <Route
              path="/purchase-orders"
              element={
                <PrivateRoute>
                  <PurchaseOrders socket={socket} />
                </PrivateRoute>
              }
            />

            <Route
              path="/purchases"
              element={
                <PrivateRoute>
                  <Purchases socket={socket} />
                </PrivateRoute>
              }
            />

            <Route
              path="/expenses"
              element={
                <PrivateRoute>
                  <Expenses socket={socket} />
                </PrivateRoute>
              }
            />

            <Route
              path="/chat"
              element={
                <PrivateRoute>
                  <ChatPage socket={socket} />
                </PrivateRoute>
              }
            />

            <Route
              path="/update-log"
              element={
                <PrivateRoute>
                  <UpdateLog socket={socket} />
                </PrivateRoute>
              }
            />

            <Route
              path="/report"
              element={
                <PrivateRoute>
                  <Report socket={socket} />
                </PrivateRoute>
              }
            />

            <Route
              path="/report/product"
              element={
                <PrivateRoute>
                  <ReportProduct socket={socket} />
                </PrivateRoute>
              }
            />

            <Route
              path="/report/customer"
              element={
                <PrivateRoute>
                  <ReportCustomer socket={socket} />
                </PrivateRoute>
              }
            />

            <Route
              path="/auth"
              element={
                <Login socket={socket} />
              }
            />
            <Route path="/logout" element={<Logout />} />
            <Route path="/404" element={<Notfound />} />
            <Route path="*" element={<Navigate to="/404" replace />} />
          </Routes>
        </Router>
      </ThemeProvider>
      <Toaster
        position="bottom-left"
        reverseOrder={false}
        toastOptions={{
          duration: 60000,
        }}
      />
    </>
  );
}
export default App;
