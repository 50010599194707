import PageSt from "../pageSt"
import { useEffect, useState } from 'react';
export default function Product({ socket }) {
    const [newMsg, setNewMsg] = useState(true);

    useEffect(() => {
        socket.on("messageResponse", () => {
            localStorage.setItem('newMsg', 'true');
            setNewMsg(false);
        });
    }, [socket])

    useEffect(() => {
        if (localStorage.getItem('newMsg')) {
            setNewMsg(false);
        }
    }, [])

    const stockOption = [
        {
            value: 1,
            label: 'ไม่บันทึกข้อมูลสต๊อกสินค้า',
            color: 'text-gray-800',
            bg: 'bg-gray-100',
        },
        {
            value: 2,
            label: 'บันทึกข้อมูลสต๊อกสินค้า',
            color: 'text-white',
            bg: 'bg-indigo-500',
        },
    ];
    return (
        <PageSt
            newMsg={newMsg}
            pageTitle="สินค้า / บริการ"
            apiPath="/product"
            popup={{
                popInput: [
                    {
                        id: 'name',
                        label: 'ชื่อสินค้า',
                        type: 'text',
                        req: true,
                        noteditable: false
                    },
                    {
                        id: 'desc',
                        label: 'รายละเอียด',
                        type: 'text',
                        noteditable: false,
                        multiline: true,
                        rows: 4
                    },
                    {
                        id: 'sku',
                        label: 'รหัสสินค้า',
                        type: 'text',
                        req: true,
                        noteditable: false
                    },
                    {
                        id: 'price',
                        label: 'ราคา',
                        type: 'number',
                        req: true,
                        noteditable: false
                    },
                    {
                        id: 'stock',
                        label: 'การจัดการสต๊อกสินค้า',
                        type: 'select',
                        options: stockOption,
                        defaultValue: '1',
                        req: true,
                        noteditable: false
                    },
                ]
            }}
            formData={{
                name: '',
                desc: '',
                sku: '',
                price: '',
                stock: '',
            }}
            tableData={{
                th: ['รหัสสินค้า / บริการ', 'ชื่อสินค้า / บริการ', 'ราคา'],
                td: ['sku', 'name', 'price']
            }}
            formWidth={'550px'}
            importButton={false}
            importscrape={false}
            importExcel={false}
        />
    )
}