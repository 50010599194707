import PageSt from "../pageSt"
import { useEffect, useState } from 'react';
export default function User({ socket }) {
    const [newMsg, setNewMsg] = useState(true);

    useEffect(() => {
        socket.on("messageResponse", () => {
            localStorage.setItem('newMsg', 'true');
            setNewMsg(false);
        });
    }, [socket])

    useEffect(() => {
        if (localStorage.getItem('newMsg')) {
            setNewMsg(false);
        }
    }, [])

    return (
        <PageSt
            newMsg={newMsg}
            pageTitle="ผู้ใช้งาน"
            apiPath="/user"
            popup={{
                popInput: [
                    {
                        id: 'username',
                        label: 'ยูสเซอร์เนม',
                        type: 'text',
                        req: true,
                        noteditable: true,
                        multiline: false
                    },
                    {
                        id: 'email',
                        label: 'อีเมล',
                        type: 'email',
                        req: true,
                        noteditable: false,
                        multiline: false
                    },
                    {
                        id: 'password',
                        label: 'รหัสผ่าน',
                        type: 'password',
                        req: true,
                        noteditable: false
                    },
                    {
                        id: 'name',
                        label: 'ชื่อ',
                        type: 'text',
                        req: true,
                        noteditable: false,
                        multiline: false
                    },
                    {
                        id: 'lname',
                        label: 'นามสกุล',
                        type: 'text',
                        noteditable: false,
                        multiline: false
                    },
                    {
                        id: 'role',
                        label: 'สิทธิการใช้งาน',
                        type: 'select',
                        options: [
                            {
                                value: '0',
                                label: '-- เลือกสิทธิการใช้งาน --',
                            },
                            {
                                value: '1',
                                label: 'ผู้ดูแลระบบ',
                            },
                            {
                                value: '2',
                                label: 'ผู้จัดการผ่ายขาย',
                            },
                            {
                                value: '3',
                                label: 'พนักงานขาย',
                            },
                            {
                                value: '4',
                                label: 'ยกเลิกสิทธิการเข้าถึง',
                            },
                        ],
                        defaultValue: '0',
                        req: true,
                        noteditable: false
                    },
                ]
            }}
            formData={{
                username: '',
                email: '',
                password: '',
                name: '',
                lname: '',
                role: ''
            }}
            tableData={{
                th: ['ชื่อผู้ใช้', 'อีเมล', 'ชื่อ', 'นามสกุล', 'สิทธิการใช้งาน'],
                td: ['username', 'email', 'name', 'lname', 'role']
            }}
        />
    )
}