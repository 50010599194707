import EmptyPage from "../emptyPage"
import siteSetting from '../components/siteSetting';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useNavigate } from "react-router-dom";
import { th } from 'react-date-range/dist/locale';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);
export default function Report({ socket }) {
    const [newMsg, setNewMsg] = useState(true);

    useEffect(() => {
        socket.on("messageResponse", () => {
            localStorage.setItem('newMsg', 'true');
            setNewMsg(false);
        });
    }, [socket])

    useEffect(() => {
        if (localStorage.getItem('newMsg')) {
            setNewMsg(false);
        }
    }, [])

    const [data, setData] = useState(null);
    const navigate = useNavigate();
    const convertDate = (date) => {
        const today = new Date(date);
        const year = today.getFullYear();
        const month = today.getMonth() + 1;
        const day = today.getDate();
        const formattedMonth = (month < 10) ? '0' + month : month;

        return year + '-' + formattedMonth + '-' + day;
    }

    const [open, setOpen] = useState(false);
    const sevenDaysEarlier = new Date();
    const [stateD, setStateD] = useState([{
        startDate: new Date(sevenDaysEarlier.setDate(sevenDaysEarlier.getDate() - 15)),
        endDate: new Date(),
        key: 'selection',
        color: '#3730a3',
    }]);
    const [queryParams, setQueryParams] = useState({
        start: convertDate(new Date(sevenDaysEarlier.setDate(sevenDaysEarlier.getDate() - 15))),
        end: convertDate(new Date())
    });
    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const startFromURL = searchParams.get('start');
        const endFromURL = searchParams.get('end');

        if (startFromURL && endFromURL) {
            //console.log('found');
            setStateD((prevState) => {
                const updatedState = [...prevState];
                updatedState[0].startDate = new Date(startFromURL);
                updatedState[0].endDate = new Date(endFromURL)
                return updatedState;
            });
            setQueryParams(prev => ({
                ...prev,
                start: startFromURL,
                end: endFromURL
            }));
        }
    }, []);


    const [tableList, setTableList] = useState(null);


    useEffect(() => {

        axios({
            method: 'get',
            url: `${siteSetting.apiUrl}/report/all?start=${queryParams.start}&end=${queryParams.end}`,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
        }).then(function (response) {
            setData(response.data);
        });

        axios({
            method: 'get',
            url: `${siteSetting.apiUrl}/report/sale-report?start=${queryParams.start}&end=${queryParams.end}`,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
        }).then(function (response) {
            //console.log(response.data);
            setTableList(response.data);
        });


    }, [queryParams]);

    function fixedNUM(num, precision = 2) {
        if (num === null) {
            return '0.00';
        }
        var roundedx = (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
        var commasx = roundedx.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return commasx;
    }

    const convertIvstatus = (status) => {
        const statusMenu = ['รอดำเนินการ', '', 'ยกเลิก', '', 'เก็บเงิน']
        return statusMenu[parseInt(status)]
    }

    const statusColor = (status) => {
        switch (status) {
            case '0':
                return {
                    color: '#1f2937',
                    bg: '#f3f4f6',
                };
            case '1':
                return {
                    color: '#1f2937',
                    bg: '#e0e7ff',
                };
            case '2':
                return {
                    color: '#1f2937',
                    bg: '#e5e7eb',
                };
            case '3':
                return {
                    color: '#1f2937',
                    bg: '#e0e7ff',
                };
            case '4':
                return {
                    color: '#1f2937',
                    bg: '#e0e7ff',
                };
            default:
                return {
                    color: '#1f2937',
                    bg: '#f3f4f6',
                };
        }
    }

    const handleChange = (event) => {
        navigate(`${event.target.value}?start=${queryParams.start}&end=${queryParams.end}`)
    };

    let sum1 = 0;
    let sum2 = 0;
    let sum3 = 0;

    const pageContent = (
        <>
            <div className="flex justify-between items-center">
                <h1 className="text-xl font-bold">รายงานวันที่ {`${convertDate(stateD[0].startDate)} ถึง ${convertDate(stateD[0].endDate)}`}</h1>
                <div className="flex gap-4">
                    <FormControl variant="standard" >
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={'/report'}
                            label="ประเภทรายงาน"
                            onChange={handleChange}
                        >
                            <MenuItem value={'/report'}>รายงานสรุปยอดขาย</MenuItem>
                            <MenuItem value={'/report/product'}>รายงานยอดขายแยกตามสินค้า</MenuItem>
                            <MenuItem value={'/report/customer'}>รายงานยอดขายแยกตามลูกค้า</MenuItem>
                        </Select>
                    </FormControl>
                    <Button variant="contained" className='flex gap-2 items-center px-4' onClick={() => setOpen(true)}>
                        <TodayOutlinedIcon />
                        เลือกช่วงวันที่
                    </Button>
                </div>

            </div>


            <div className="my-4" style={{ height: 'auto' }}>
                <Card>
                    <CardContent>
                        {data !== null &&
                            <Line
                                width={100}
                                height={300}
                                options={{
                                    maintainAspectRatio: false,
                                    interaction: { intersect: false },
                                    scale: { ticks: { precision: 0 } },
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            position: 'top',
                                            labels: {
                                                font: {
                                                    family: 'Noto Sans Thai',
                                                }
                                            }
                                        },
                                    },
                                }}

                                data={{
                                    labels: data.quotation.map(item => item.day),
                                    datasets: [
                                        {
                                            label: "ใบเสนอราคา",
                                            data: data.quotation.map(item => item.Total_Payment),
                                            backgroundColor: "#facc15",
                                            borderColor: "#facc15",
                                            pointBackgroundColor: "#facc15",
                                            pointBorderColor: "#facc15",
                                        },
                                        {
                                            label: "ใบแจ้งหนี้",
                                            data: data.invoice.map(item => item.Total_Payment),
                                            backgroundColor: "#3730a3",
                                            borderColor: "#3730a3",
                                            pointBackgroundColor: "#3730a3",
                                            pointBorderColor: "#3730a3",
                                        },
                                        {
                                            label: "ใบเสร็จ",
                                            data: data.receipt.map(item => item.Total_Payment),
                                            backgroundColor: "#c026d3",
                                            borderColor: "#c026d3",
                                            pointBackgroundColor: "#c026d3",
                                            pointBorderColor: "#c026d3",
                                        }
                                    ],
                                }}
                            />
                        }
                    </CardContent>
                </Card>

            </div>

            <Card>
                <CardContent>
                    {tableList !== null &&
                        <table className="w-full">
                            <thead>
                                <tr className="border-b border-b-slate-200">
                                    <th className="text-left p-3">เลขที่เอกสาร</th>
                                    <th className="text-left p-3">วันที่</th>
                                    <th className="text-left p-3">ชื่อลูกค้า/ชื่อโปรเจ็ค</th>
                                    <th className="text-center p-3">สถานะ</th>
                                    <th className="text-right p-3">มูลค่า</th>
                                    <th className="text-right p-3">ภาษีมูลค่าเพิ่ม</th>
                                    <th className="text-right p-3">ยอดรวมสุทธิ</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableList.map((data, index) => {

                                    sum1 += parseInt(data.value);
                                    sum2 += parseInt(data.vat);
                                    sum3 += parseInt(data.total);
                                    return (
                                        <tr className="border-b border-b-slate-200 align-top" key={index}>
                                            <td className="text-left p-3">{data.docno}</td>
                                            <td className="text-left p-3">{data.date}</td>
                                            <td className="text-left p-3">{data.customer}<br /><small>{data.project}</small></td>
                                            <td className="text-center p-3">
                                                <span className="px-4 py-1 rounded-full"
                                                    style={{ color: statusColor(data.status).color, backgroundColor: statusColor(data.status).bg }}
                                                >
                                                    {convertIvstatus(data.status)}
                                                </span>
                                            </td>
                                            <td className="text-right p-3">{fixedNUM(data.value)}</td>
                                            <td className="text-right p-3">{fixedNUM(data.vat)}</td>
                                            <td className="text-right p-3">{fixedNUM(data.total)}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                            <tfoot>
                                <tr className="border-b border-b-slate-200 align-top">
                                    <td className="text-right p-3" colSpan="4">รวม</td>
                                    <td className="text-right p-3">{fixedNUM(sum1)}</td>
                                    <td className="text-right p-3">{fixedNUM(sum2)}</td>
                                    <td className="text-right p-3">{fixedNUM(sum3)}</td>
                                </tr>
                            </tfoot>
                        </table>
                    }
                </CardContent>
            </Card>



            <Dialog
                maxWidth={'lg'}
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>เลือกช่วงเวลารายงาน</DialogTitle>
                <DialogContent>
                    <DateRangePicker
                        editableDateInputs={true}
                        onChange={item => setStateD([item.selection])}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        ranges={stateD}
                        //minDate={new Date(minDate)}
                        //maxDate={new Date(maxDate)}
                        color={'#3730a3'}
                        direction="horizontal"
                        locale={th}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>ยกเลิก</Button>
                    <Button onClick={() => {
                        setQueryParams(prev => ({
                            ...prev,
                            start: convertDate(stateD[0].startDate),
                            end: convertDate(stateD[0].endDate)
                        }));
                        setOpen(false);
                        //setStartQ(convertDate(stateD[0].startDate));
                        //setEndQ(convertDate(stateD[0].endDate));
                        //handleClose();
                        //console.log(stateD);
                    }}>ตกลง</Button>
                </DialogActions>
            </Dialog>
        </>
    );


    return (
        <EmptyPage
            newMsg={newMsg}
            pageTitle="รายงาน"
            formWidth={'550px'}
            pageContent={pageContent}
        />
    )
}
/*
Filter By
 - สถานะเอกสาร 
 - วัน, เดือน
*/





