import React, { useEffect, useState, useRef } from 'react'
import ChatBody from '../components/ChatBody'
import ChatFooter from '../components/ChatFooter'
import siteSetting from "../components/siteSetting"
import SideNav from "../components/sidenav";
import AppHeader from "../components/header";

const ChatPage = ({ socket }) => {

    const [messages, setMessages] = useState([])
    const [typingStatus, setTypingStatus] = useState("")
    const lastMessageRef = useRef(null);


    useEffect(() => {
        socket.on("messageResponse", data => setMessages([...messages, data]))
        console.log('incoming message');
    }, [socket, messages])

    useEffect(() => {
        socket.on("typingResponse", data => setTypingStatus(data))
    }, [socket])

    useEffect(() => {
        // 👇️ scroll to bottom every time messages change
        lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    useEffect(() => {
        document.title = 'Chat - ' + siteSetting.siteName;

    }, [])


    const [notiCount, setNotiCount] = useState(parseInt(localStorage.getItem('noticount')));

    useEffect(() => {
        const interval = setInterval(() => {
            if (parseInt(localStorage.getItem('noticount')) !== notiCount) {
                setNotiCount(parseInt(localStorage.getItem('noticount')))
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [notiCount]);

    return (
        <>
            <div className="app-layout-modern flex flex-auto flex-col">
                <div className="flex flex-auto min-w-0">
                    <SideNav />
                    <div className="flex flex-col flex-auto min-h-screen min-w-0 relative w-full bg-main-bg-color justify-between max-h-screen">
                        <AppHeader
                            pageTitle="แชท"
                            disableFrom={true}
                        //notiCount={notiCount}
                        //newMsg={props.newMsg}
                        />
                        <div className="relative overflow-x-hidden px-7 py-5 mb-auto" id="chat-warp">
                            <div className='flex items-center justify-between'>
                                <div className='flex gap-4 items-center'>
                                    <h1 className='text-xl font-bold'>แชท</h1>
                                </div>
                            </div>
                            <div className='table-responsive py-7 min-h-80'>
                                <div className='flex flex-wrap' >
                                    <ChatBody messages={messages} typingStatus={typingStatus} lastMessageRef={lastMessageRef} />
                                </div>
                            </div>
                        </div>
                        <ChatFooter socket={socket} />
                    </div>
                </div>
            </div>

        </>
    )
}

export default ChatPage