import { useEffect, useState } from 'react';
import siteSetting from "../components/siteSetting"
import PageSt from "../pageSt"
import axios from 'axios';

export default function Leads({ socket }) {

    const [leadGroup, setLeadGroup] = useState({});
    const [users, setUsers] = useState({});

    const fetchGroup = (offset = 0, keyword = '') => {
        axios({
            method: 'get',
            url: siteSetting.apiUrl + '/lead-group?offset=' + offset + '&keyword=' + keyword,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
        }).then(function (response) {
            setLeadGroup(response.data.data);
        });
    }

    const fetchUser = (offset = 0, keyword = '') => {
        axios({
            method: 'get',
            url: siteSetting.apiUrl + `/user?offset=${offset}&keyword=${keyword}`,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
        }).then(function (response) {
            setUsers(response.data.data);
        });
    }

    useEffect(() => {
        fetchGroup();
        fetchUser();
    }, []);

    const [newMsg, setNewMsg] = useState(true);

    useEffect(() => {
        socket.on("messageResponse", () => {
            localStorage.setItem('newMsg', 'true');
            setNewMsg(false);
        });
    }, [socket])

    useEffect(() => {
        if (localStorage.getItem('newMsg')) {
            setNewMsg(false);
        }
    }, [])

    const statusOption = [
        {
            value: 1,
            label: 'ยังไม่มีการติดต่อ',
            color: 'text-gray-800',
            bg: 'bg-gray-100',
        },
        {
            value: 2,
            label: 'ไม่สามารถติดต่อได้',
            color: 'text-white',
            bg: 'bg-red-600',
        },
        {
            value: 3,
            label: 'ติดต่อใหม่ในอนาคต',
            color: 'text-white',
            bg: 'bg-indigo-500',
        },
        {
            value: 4,
            label: 'เปิดการขาย, กำลังติดตาม',
            color: 'text-white',
            bg: 'bg-green-500',
        },
        {
            value: 5,
            label: 'ห้ามติดต่อ',
            color: 'text-gray-800',
            bg: 'bg-gray-200',
        },
    ];


    return (
        <PageSt
            newMsg={newMsg}
            pageTitle="รายชื่อ"
            apiPath="/lead"
            popup={{
                popInput: [
                    {
                        id: 'name',
                        label: 'ชื่อผู้ติดต่อ',
                        type: 'text',
                        req: true,
                        noteditable: false
                    },
                    {
                        id: 'company',
                        label: 'บริษัท',
                        type: 'text',
                        noteditable: false
                    },
                    {
                        id: 'address',
                        label: 'ที่อยู่',
                        type: 'text',
                        noteditable: false,
                        multiline: true,
                        rows: 2
                    },
                    {
                        id: 'desc',
                        label: 'รายละเอียดเพิ่มเติม',
                        type: 'text',
                        noteditable: false,
                        multiline: true,
                        rows: 4
                    },
                    {
                        id: 'phone',
                        label: 'โทรศัพท์',
                        type: 'text',
                        noteditable: false
                    },
                    {
                        id: 'email',
                        label: 'อีเมล',
                        type: 'text',
                        noteditable: false
                    },
                    {
                        id: 'owner',
                        label: 'เจ้าของ',
                        type: 'select',
                        options: users,
                        defaultValue: '1',
                        req: true,
                        noteditable: false
                    },
                    {
                        id: 'group',
                        label: 'กลุ่มรายชื่อ',
                        type: 'select',
                        options: leadGroup,
                        defaultValue: '1',
                        req: true,
                        noteditable: false
                    },
                    {
                        id: 'status',
                        label: 'สถานะ',
                        type: 'select',
                        options: statusOption,
                        defaultValue: '1',
                        req: true,
                        noteditable: false
                    },
                ]
            }}
            formData={{
                name: '',
                address: '',
                company: '',
                desc: '',
                phone: '',
                email: '',
                owner: '',
                group: '',
                status: '',
            }}
            tableData={{
                th: ['ชื่อ', 'บริษัท', 'โทรศัพท์', 'สถานะ'],
                td: ['name', 'company', 'phone', 'status']
            }}
            formWidth={'550px'}
            filter={[
                {
                    urlParam: '&owner=' + localStorage.getItem('token'),
                    type: 'checkbox',
                    label: 'แสดงเฉพาะรายชื่อของฉัน'
                }
            ]}
            importButton={true}
            importscrape={true}
            importExcel={true}
            dataOwner={users}
            dataLeadGroup={leadGroup}
            statusOption={statusOption}
        />
    )
}