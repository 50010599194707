import DocumentTemplate from "./documentTemplate"
import { useEffect, useState } from 'react';
export default function Invoice({ socket }) {
    const [newMsg, setNewMsg] = useState(true);

    useEffect(() => {
        socket.on("messageResponse", () => {
            localStorage.setItem('newMsg', 'true');
            setNewMsg(false);
        });
    }, [socket])

    useEffect(() => {
        if (localStorage.getItem('newMsg')) {
            setNewMsg(false);
        }
    }, [])
    return (
        <DocumentTemplate
            newMsg={newMsg}
            pageTitle='ใบวางบิล/ใบแจ้งหนี้'
            apiPath='/invoice'
            docPrefix='BL'
            statusMenu={[
                {
                    value: 0,
                    label: 'รอวางบิล'
                },
                {
                    value: 3,
                    label: 'สร้างใบเสร็จรับเงิน'
                },
                {
                    value: 2,
                    label: 'ยกเลิก'
                },
            ]}
        />
    )
}