import { useState, useRef, forwardRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import AttachmentOutlinedIcon from '@mui/icons-material/AttachmentOutlined';

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const DragAndDropImageUpload = ({ onCrop, defaultImage }) => {

    const cropperRef = useRef(null);
    const [image, setImage] = useState(null);
    const [open, setOpen] = useState(false);
    const [openNoti, setOpenNoti] = useState(false);
    const [notiMsg, setNotiMsg] = useState({
        msg: '',
        type: 'error'
    });
    const handleCloseNoti = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenNoti(false);
    };
    const [previewBg, setPreviewBg] = useState(null);

    const handleDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        if (file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png") {
            const reader = new FileReader();
            reader.onload = (readerEvent) => {
                setImage(readerEvent.target.result);
                setOpen(true);
            };
            reader.readAsDataURL(file);
        } else {
            setNotiMsg(prev => ({
                ...prev,
                msg: 'รูปแบบไฟล์ไม่ถูกต้อง รองรับ jpeg, jpg, หรือ png เท่านั้น',
                type: 'error'
            }));
            setOpenNoti(true);
        }
    };

    const handleClick = () => {
        const input = document.createElement("input");
        input.type = "file";
        input.onchange = (inputEvent) => {
            const file = inputEvent.target.files[0];
            if (file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png") {
                const reader = new FileReader();
                reader.onload = (readerEvent) => {
                    setImage(readerEvent.target.result);
                    setOpen(true);
                };
                reader.readAsDataURL(file);
            } else {
                setNotiMsg(prev => ({
                    ...prev,
                    msg: 'รูปแบบไฟล์ไม่ถูกต้อง รองรับ jpeg, jpg, หรือ png เท่านั้น',
                    type: 'error'
                }));
                setOpenNoti(true);
            }
        };
        input.click();
    };

    const handleCrop = () => {
        const imageElement = cropperRef?.current;
        if (!imageElement) {
            return
        }
        const cropper = imageElement?.cropper;
        console.log(cropper.getCroppedCanvas().toDataURL());
        onCrop(cropper.getCroppedCanvas().toDataURL());
        setPreviewBg(cropper.getCroppedCanvas().toDataURL());
    };

    return (
        <>
            <button
                className="border-2 border-dashed p-8 rounded-md w-full text-gray-500"
                onClick={handleClick}
                onDrop={handleDrop}
                onDragOver={(event) => event.preventDefault()}
                style={{
                    backgroundImage: `url('${previewBg || defaultImage}')`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center'
                }}
            >
                
                {!image && defaultImage == null ? (<><AttachmentOutlinedIcon/> คลิกเลือก หรือลากไฟล์รูปภาพลงที่นี่</>): ''}
            </button>

            <Dialog
                maxWidth={'lg'}
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle></DialogTitle>
                <DialogContent>
                    {image && <div style={{ width: 400, height: 400, border: "1px solid black" }}>
                        {image && (
                            <Cropper
                                ref={cropperRef}
                                src={image}
                                style={{ height: 400, width: "100%" }}
                                aspectRatio={20 / 7}
                                guides={false}
                                minCropBoxWidth={500}
                                minCropBoxHeight={175}
                                cropBoxResizable={false}
                            />
                        )}
                    </div>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>ยกเลิก</Button>
                    <Button onClick={() => {
                        handleCrop()
                        setOpen(false);
                    }}>ตกลง</Button>
                </DialogActions>
            </Dialog>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={openNoti} autoHideDuration={6000} onClose={handleCloseNoti}>
                <Alert onClose={handleCloseNoti} severity={notiMsg.type} sx={{ width: '100%' }}>
                    {notiMsg.msg}
                </Alert>
            </Snackbar>
        </>
    );
};

export default DragAndDropImageUpload;