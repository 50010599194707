import { useState, useEffect, useRef, forwardRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import siteSetting from "../components/siteSetting"
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputAdornment from '@mui/material/InputAdornment';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import CircularProgress from '@mui/material/CircularProgress';
import DragHandleOutlinedIcon from '@mui/icons-material/DragHandleOutlined';

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function StockManagement({ socket }) {

    const { id } = useParams();
    const navigate = useNavigate();
    const [openNoti, setOpenNoti] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [inputFields, setInputFields] = useState([{
        stockId: '',
        stockLot: '',
        stockValue: '',
        stockNote: '',
        stockDate: dayjs(new Date()).format('YYYY/MM/DD'),
        stockError: false,
    }]);
    const [notiMsg, setNotiMsg] = useState({
        msg: '',
        type: 'error'
    });
    const [stockState, setStockState] = useState({
        productName: '',
        stockCutOption: 3,
        stocktable: inputFields,
        notifyStatus: 1,
        notifyItems: 20
    });

    const [logData, setLogData] = useState([]);
    const [allItems, setAllItems] = useState(0);

    const addInputField = () => {
        setInputFields([...inputFields, {
            stockId: '',
            stockLot: '',
            stockValue: '',
            stockNote: '',
            stockDate: dayjs(new Date()).format('YYYY/MM/DD'),
            stockError: false,
        }])
    }

    const removeInputFields = (index) => {
        const rows = [...inputFields];
        rows.splice(index, 1);
        setInputFields(rows);
    }

    const handleChange = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...inputFields];
        list[index][name] = value;
        if (value !== '') {
            list[index]['stockError'] = false;
        }
        setInputFields(list);
    }

    const handleDateChange = (index, value) => {
        let list = [...inputFields];
        list[index]['stockDate'] = value;
        setInputFields(list);
    }

    const dragItem = useRef(null);
    const dragOverItem = useRef(null);

    const handleSort = () => {
        let stockItems = [...inputFields];
        const draggedItemsContent = stockItems.splice(dragItem.current, 1)[0];
        stockItems.splice(dragOverItem.current, 0, draggedItemsContent);
        dragItem.current = null;
        dragOverItem.current = null;
        setInputFields(stockItems);
    }

    const handleCloseNoti = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenNoti(false);
    };

    const updateStock = () => {

        setDisabled(true);

        let errorCount = 0;
        for (const [key, value] of Object.entries(inputFields)) {
            if (inputFields[key].stockDate === '' || inputFields[key].stockLot === '' || inputFields[key].stockValue === '') {
                errorCount++;
                let list = [...inputFields];
                list[key]['stockError'] = true;
                setInputFields(list);
                setDisabled(false);
                console.log(value);
            }
        }

        if (errorCount > 0) {
            return;
        }

        axios({
            method: 'put',
            url: `${siteSetting.apiUrl}/product/stock/${id}`,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
            data: {
                option: stockState.stockCutOption,
                stock: inputFields,
                notifyStatus: stockState.notifyStatus,
                notifyItems: stockState.notifyItems
            }
        }).then(function (response) {
            setDisabled(false);
            console.log(response.data);

            if (response.data.status === 500) {
                setNotiMsg(prev => ({
                    ...prev,
                    msg: response.data.msg,
                    type: 'error'
                }));
                setOpenNoti(true);
            }
            if (response.data.status === 200) {
                setNotiMsg(prev => ({
                    ...prev,
                    msg: response.data.msg,
                    type: 'success'
                }));
                setOpenNoti(true);

                setLogData(response.data.update.data.log);
                if (response.data.update.data.stock.length > 0) {
                    setInputFields(response.data.update.data.stock);
                }
                setStockState(prev => ({
                    ...prev,
                    notifyStatus: response.data.update.data.notify,
                    notifyItems: response.data.update.data.notify_value
                }));
            }
        });
    }


    useEffect(() => {
        axios({
            method: 'get',
            url: `${siteSetting.apiUrl}/product/${id}`,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
        }).then(function (response) {
            console.log(response.data);

            if (response.data.status === 500) {
                setNotiMsg(prev => ({
                    ...prev,
                    msg: response.data.msg,
                    type: 'error'
                }));
                setOpenNoti(true);
            }
            if (response.data.status === 200) {
                setStockState(prev => ({
                    ...prev,
                    productName: response.data.data.name,
                    stockCutOption: response.data.data.option,
                    notifyStatus: response.data.data.notify,
                    notifyItems: response.data.data.notify_value
                }));
                setLogData(response.data.data.log);
                if (response.data.data.stock.length > 0) {
                    setInputFields(response.data.data.stock);
                }
            }
        });
    }, [id]);


    useEffect(() => {
        let count = 0;
        for (const key of Object.entries(inputFields)) {
            if (!isNaN(parseInt(key[1].stockValue))) {
                count += parseInt(key[1].stockValue);
            }
        }
        setAllItems(count);
    }, [inputFields]);



    const convertlogType = (type) => {
        let pillType;
        switch (type) {
            case 'ADD':
                pillType = <span className="px-4 py-1 text-xs whitespace-nowrap rounded-full text-white bg-indigo-800">{type}</span>
                break;
            case 'UPDATE':
                pillType = <span className="px-4 py-1 text-xs whitespace-nowrap rounded-full text-white bg-green-500">{type}</span>
                break;
            case 'DELETE':
                pillType = <span className="px-4 py-1 text-xs whitespace-nowrap rounded-full text-white bg-red-600">{type}</span>
                break;
            default:
                pillType = <span className="px-4 py-1 text-xs whitespace-nowrap rounded-full text-gray-800 bg-gray-100">{type}</span>
        }
        return pillType;
    }

    return (
        <>
            <div>
                <div className="border-b border-b-slate-200">
                    <div className="flex justify-between items-center p-4">
                        <h1 className="text-xl font-bold">
                            จัดการสต็อกสินค้า: {stockState.productName}
                        </h1>
                        <div className="flex gap-4">
                            <Button variant="outlined" onClick={() => navigate('/products')}>ปิดหน้าต่าง</Button>
                            <Button variant="contained" onClick={updateStock} disabled={disabled}>
                                บันทึกข้อมูล
                                {disabled && <CircularProgress size="1rem" />}
                            </Button>
                            <button onClick={() => navigate('/products')}>
                                <CloseOutlinedIcon />
                            </button>
                        </div>
                    </div>
                </div>


                <div className="flex p-4">

                    <div className="w-9/12 p-2">
                        <div className="bg-white p-8 border rounded" style={{ maxHeight: '45vh', overflowY: 'scroll' }}>
                            <h2 className="font-bold mb-5">สต็อกสินค้า</h2>
                            <table className="w-full">
                                <thead>
                                    <tr className="border-b border-b-slate-200">
                                        <td className="text-center pb-3 px-3"></td>
                                        <td className="text-center w-4 pb-3 px-3">ลำดับ</td>
                                        <td className="text-left pb-3">หมายเลขล็อต</td>
                                        <td className="text-left pb-3 pr-6">จำนวนสินค้า</td>
                                        <td className="text-left pb-3 pr-6">วันที่</td>
                                        <td className="text-left pb-3 pr-6">หมายเหตุ</td>
                                        <td className=""></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        inputFields.map((data, index) => {
                                            return (
                                                <tr
                                                    className="align-baseline border-b border-b-slate-200 bg-white"
                                                    key={index}
                                                    draggable
                                                    onDragStart={(e) => (dragItem.current = index)}
                                                    onDragEnter={(e) => (dragOverItem.current = index)}
                                                    onDragEnd={handleSort}
                                                    onDragOver={(e) => e.preventDefault()}
                                                >
                                                    <td className="text-center px-2 py-4 pb-5 align-bottom cursor-grab">
                                                        <DragHandleOutlinedIcon />
                                                    </td>
                                                    <td className="text-center px-2 py-4 align-bottom">
                                                        <div className="pb-1">{index + 1}</div>
                                                    </td>
                                                    <td className="px-2 py-4">
                                                        <TextField
                                                            error={data.stockError && data.stockLot === '' ? true : false}
                                                            helperText={data.stockError && data.stockLot === '' && 'กรุณาตั้งชื่อ Lot'}
                                                            label="หมายเลขล็อต"
                                                            className="w-full"
                                                            placeholder="หมายเลขล็อต"
                                                            variant="standard"
                                                            name="stockLot"
                                                            type="text"
                                                            onChange={(evnt) => { handleChange(index, evnt) }}
                                                            value={data.stockLot}
                                                        />
                                                    </td>
                                                    <td className="px-2 py-4">
                                                        <TextField
                                                            error={data.stockError && data.stockValue === '' ? true : false}
                                                            helperText={data.stockError && data.stockValue === '' && 'กรุณากรอกจำนวนสินค้า'}
                                                            label="จำนวนสินค้า"
                                                            className="w-full"
                                                            placeholder="จำนวนสินค้า"
                                                            inputProps={{ min: 0, style: { textAlign: 'left' } }}
                                                            variant="standard"
                                                            name="stockValue"
                                                            type="number"
                                                            onChange={(evnt) => { handleChange(index, evnt) }}
                                                            value={data.stockValue}
                                                        />
                                                    </td>
                                                    <td className="px-2 py-4">
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DatePicker
                                                                renderInput={(props) =>
                                                                    <TextField
                                                                        {...props}
                                                                        variant="standard"
                                                                        error={data.stockError && data.stockDate === '' ? true : false}
                                                                        helperText={data.stockError && data.stockDate === '' && 'กรุณาเลือกวันที่'}
                                                                    />
                                                                }
                                                                ampm={false}
                                                                label="วันที่"
                                                                inputFormat="YYYY/MM/DD"
                                                                value={
                                                                    data.stockDate
                                                                }
                                                                onChange={(newValue) => {
                                                                    if (newValue !== null && newValue.$d !== 'Invalid Date') {
                                                                        handleDateChange(index, dayjs(newValue.$d).format('YYYY/MM/DD'))
                                                                    }
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </td>
                                                    <td className="px-2 py-4 text-right">
                                                        <TextField
                                                            label="หมายเเหตุ"
                                                            className="w-full"
                                                            placeholder="หมายเเหตุ"
                                                            fullWidth
                                                            variant="standard"
                                                            name="stockNote"
                                                            type="text"
                                                            onChange={(evnt) => { handleChange(index, evnt) }}
                                                            value={data.stockNote}
                                                        />
                                                    </td>
                                                    <td className="px-0 py-2 text-right  align-bottom">
                                                        {(inputFields.length !== 1) ? <button className="pb-3 pl-2"
                                                            onClick={() => removeInputFields(index)}><CloseOutlinedIcon /></button> : ''}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }

                                </tbody>
                            </table>
                            <div className="mt-4">
                                <Button variant="outlined" onClick={addInputField}> + เพิ่มรายการ</Button>
                            </div>
                        </div>
                        {logData.length > 0 &&
                            <div className="bg-white p-8 border rounded mt-4" style={{ maxHeight: '40vh', overflowY: 'scroll' }}>
                                <h2 className="font-bold mb-5">ประวัติ</h2>
                                <table className="w-full">
                                    <thead>
                                        <tr className="border-b border-b-slate-200">
                                            <td className="text-left p-3">วัน - เวลา</td>
                                            <td className="text-left p-3">ประเภท</td>
                                            <td className="text-left p-3">การกระทำ</td>
                                            <td className="text-left p-3">คงเหลือ</td>
                                            <td className="text-left p-3">โดย</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {logData.map((data, index) => {
                                            return (
                                                <tr className="align-baseline border-b border-t-slate-200 bg-white" key={index}>
                                                    <td className="text-left p-3">{data.date}</td>
                                                    <td className="text-left p-3">{convertlogType(data.type)}</td>
                                                    <td className="text-left p-3">{data.text}</td>
                                                    <td className="text-left p-3">{data.left}</td>
                                                    <td className="text-left p-3">{data.user}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        }

                    </div>
                    <div className="w-3/12 p-2">

                        <div className="p-8 border rounded">
                            <h2 className="font-bold mb-5 text-center">จำนวนสต็อกทั้งหมด</h2>
                            <div className="text-center font-bold text-xl">{allItems}</div>
                        </div>

                        <div className="mt-4 p-8 border rounded">
                            <h2 className="font-bold mb-5">การตัดสต็อก</h2>
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    value={stockState.stockCutOption || '3'}
                                    onChange={(e) => {
                                        setStockState(prev => ({
                                            ...prev,
                                            stockCutOption: e.target.value,
                                        }));
                                    }}
                                    name="radio-buttons-group"
                                >
                                    <FormControlLabel value="1" control={<Radio />} label="เมื่อออกใบเสนอราคา" />
                                    <FormControlLabel value="2" control={<Radio />} label="เมื่อออกใบแจ้งหนี้" />
                                    <FormControlLabel value="3" control={<Radio />} label="เมื่อออกใบเสร็จ" />
                                </RadioGroup>
                            </FormControl>
                        </div>

                        <div className="mt-4 p-8 border rounded">
                            <h2 className="font-bold mb-5">การแจ้งเตือน</h2>
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label-2"

                                    value={stockState.notifyStatus}
                                    onChange={(e) => {
                                        setStockState(prev => ({
                                            ...prev,
                                            notifyStatus: e.target.value,
                                        }));
                                    }}
                                    name="radio-buttons-group-2"
                                >
                                    <FormControlLabel value="1" control={<Radio />} label="ปิดการแจ้งเตือน" />
                                    <FormControlLabel value="2" control={<Radio />} label="เปิดการแจ้งเตือน" />
                                </RadioGroup>
                            </FormControl>

                            <div className={stockState.notifyStatus === '2' ? 'block' : 'hidden'}>
                                <TextField
                                    value={stockState.notifyItems}
                                    onChange={(e) => {
                                        let setvalue = parseInt(e.target.value);
                                        if (setvalue < 1) {
                                            return;
                                        }
                                        setStockState(prev => ({
                                            ...prev,
                                            notifyItems: e.target.value,
                                        }));
                                    }}
                                    fullWidth
                                    placeholder="0"
                                    variant="standard"
                                    type="number"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">เมื่อสินค้าเหลือน้อยกว่า</InputAdornment>,
                                        endAdornment: <InputAdornment position="end">รายการ</InputAdornment>,
                                        min: 1
                                    }}
                                    className={'center-input'}
                                />
                            </div>

                        </div>
                    </div>

                </div>
            </div>
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={openNoti} autoHideDuration={6000} onClose={handleCloseNoti}>
                <Alert onClose={handleCloseNoti} severity={notiMsg.type} sx={{ width: '100%' }}>
                    {notiMsg.msg}
                </Alert>
            </Snackbar>
        </>
    );
}

/*
1. แสดงรายการ Lot, Stock สามารถเพิ่ม ลบ หรือแก้ไขได้
2. กราฟแสดงการตัดสต็อก 
3. ส่วนจ่ายออก โดยไม่ผ่ายระบบ
4. log การรับเข้า จ่ายออก(ออกใบเสร็จ/ใบส่งของ, จ่ายออกแบบไม่ผ่ายระบบ)
5. ตัวเลือกการตัดสต็อก 'เมื่อออกใบเสนอราคา', 'เมื่อออกใบแจ้งหนี้', 'เมื่อออกใบเสร็จ'
6. การ Reorder lot row เพื่อเลือกการตัดสต็อกจาก lot ที่กำหนด

*/