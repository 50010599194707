import PageSt from "../pageSt"
import { useEffect, useState } from 'react';
export default function LeadsGroup({ socket }) {
    const [newMsg, setNewMsg] = useState(true);

    useEffect(() => {
        socket.on("messageResponse", () => {
            localStorage.setItem('newMsg', 'true');
            setNewMsg(false);
        });
    }, [socket])

    useEffect(() => {
        if (localStorage.getItem('newMsg')) {
            setNewMsg(false);
        }
    }, [])

    return (
        <PageSt
            newMsg={newMsg}
            pageTitle="กลุ่มรายชื่อ"
            apiPath="/lead-group"
            popup={{
                popInput: [
                    {
                        id: 'name',
                        label: 'ชื่อกลุ่ม',
                        type: 'text',
                        req: true,
                        noteditable: false,
                        multiline: false
                    },
                    {
                        id: 'description',
                        label: 'รายละเอียด',
                        type: 'text',
                        noteditable: false,
                        multiline: true,
                        rows: 4
                    },
                ]
            }}
            formData={{
                name: '',
                description: '',
            }}
            tableData={{
                th: ['ชื่อกลุ่ม', 'รายละเอียด'],
                td: ['name', 'description']
            }}
        />
    )
}