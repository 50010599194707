import siteSetting from "../components/siteSetting"
import { useEffect, useState, forwardRef, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import SideNav from "../components/sidenav";
import AppHeader from "../components/header";
import Button from '@mui/material/Button';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import THBText from 'thai-baht-text'
import CircularProgress from '@mui/material/CircularProgress';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Slide from '@mui/material/Slide';
import PageSKT from '../components/tableSkt';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import Pagination from '@mui/material/Pagination';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import DragHandleOutlinedIcon from '@mui/icons-material/DragHandleOutlined';
import { NumericFormat } from 'react-number-format';

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export default function DocumentTemplate(props) {

    const navigate = useNavigate();
    const [prefix, setPrefix] = useState(props.docPrefix);
    const [pageTitle, setPageTitle] = useState(props.pageTitle);
    const [apiPath, setApiPath] = useState(props.apiPath);
    const [disabled, setDisabled] = useState(false);
    const [open, setOpen] = useState(false);
    const [openNoti, setOpenNoti] = useState(false);
    const [docstate, setDocState] = useState({
        docNo: '',
        docTitle: '',
        docType: '',
        docStatus: '',
        docDesc: '',
        companyId: '',
        clientName: '',
        address: '',
        branch: '',
        company: '',
        contactName: '',
        email: '',
        phone: '',
        tax: '',
        docDate: dayjs(new Date()).format('YYYY/MM/DD'),
        docDue: dayjs(new Date()).format('YYYY/MM/DD'),
        docOwnerID: localStorage.getItem('user'),
        docOwnerName: localStorage.getItem('name'),
        docManagerID: '',
        docManagerName: '',
        docManagerNameObj: '',
        docRemark: pageTitle === "ใบสั่งซื้อ" || pageTitle === "ใบรับสินค้า" || pageTitle === "ค่าใช้จ่าย" ? "" : "ช่องทางชำระค่าบริการ\nบัญชีธนาคารกสิกรไทย ชื่อบัญชี บจก.อินฟินิตี้ คอนเทนท์ เลขบัญชี 038-1-91054-7\nส่งมอบงานภายใน 30 วัน",
        defualtVat: true
    });
    const [pageData, setpageData] = useState(null);
    const [page, setPage] = useState({
        count: 0,
        allpage: 0,
        page: 1
    });
    const handlePageChange = (event, value) => {
        fetchData(value - 1)
    };

    const dragItem = useRef(null);
    const dragOverItem = useRef(null);

    const [openAlert, setOpenAlert] = useState(false);
    const [actionID, setActionID] = useState(null);

    const [installment, setInstallment] = useState(false);
    const [installmentPerc, setInstallmentPerc] = useState(100);
    const [installmentValue, setInstallmentValue] = useState(0);
    const [refDocument, setRefDocument] = useState(null);
    const [refDocumentID, setRefDocumentID] = useState(null);

    function fixedNUM(num, precision) {
        //var numx = parseFloat(num);
        //var roundedx = numx.toFixed(decimal);
        var roundedx = (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
        var commasx = roundedx.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return commasx;
    }


    const fetchData = (offset = 0, keyword = '', query = '') => {
        axios({
            method: 'get',
            url: siteSetting.apiUrl + apiPath + actionType.actionSearch + `?offset=${offset}&keyword=${keyword}` + query,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
        }).then(function (response) {
            setpageData(response.data);
            setPage(prev => ({
                ...prev,
                count: response.data.count,
                allpage: response.data.allpage,
                page: response.data.offset + 1
            }));
        });
    }

    const deleteData = () => {
        setOpenAlert(false);
        axios({
            method: 'delete',
            url: siteSetting.apiUrl + apiPath + '/delete/' + actionID,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
        }).then(function (response) {
            if (response.data.status === 500) {
                setNotiMsg(prev => ({
                    ...prev,
                    msg: response.data.msg,
                    type: 'error'
                }));
                setOpenNoti(true);
            }
            if (response.data.status === 200) {
                setNotiMsg(prev => ({
                    ...prev,
                    msg: response.data.msg,
                    type: 'success'
                }));
                setOpenNoti(true);
                fetchData();
                setOpen(false);
                clearState();
            }
        });
    }

    const getLastQuote = (path) => {
        axios({
            method: 'get',
            url: siteSetting.apiUrl + `${path}/last`,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
        }).then(function (response) {
            if (response.data.status === 200) {
                setDocState(prev => ({
                    ...prev,
                    docNo: response.data.quote,
                }));
            }
        });
    }


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleCloseNoti = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenNoti(false);
    };

    const [notiMsg, setNotiMsg] = useState({
        msg: '',
        type: 'error'
    });

    const [actionType, setActionType] = useState({
        actionBtn: 'สร้าง',
        actionUrl: '/addnew',
        actionMethod: 'post',
        actionSearch: ''
    });

    const clearState = () => {
        setDocState(prev => ({
            ...prev,
            docNo: '',
            docTitle: '',
            docType: '',
            docStatus: '',
            docDesc: '',
            companyId: '',
            clientName: '',
            address: '',
            branch: '',
            company: '',
            contactName: '',
            email: '',
            phone: '',
            tax: '',
            docDate: dayjs(new Date()).format('YYYY/MM/DD'),
            docDue: dayjs(new Date()).format('YYYY/MM/DD'),
            docOwnerID: localStorage.getItem('user'),
            docOwnerName: localStorage.getItem('name'),
            docManagerID: '',
            docManagerName: '',
            docManagerNameObj: '',
            docRemark: pageTitle === "ใบสั่งซื้อ" || pageTitle === "ใบรับสินค้า" || pageTitle === "ค่าใช้จ่าย" ? "" : "ช่องทางชำระค่าบริการ\nบัญชีธนาคารกสิกรไทย ชื่อบัญชี บจก.อินฟินิตี้ คอนเทนท์ เลขบัญชี 038-1-91054-7\nส่งมอบงานภายใน 30 วัน",
            defualtVat: true
        }));
        setInputFields([{
            productID: '',
            productSKU: '',
            productDescription: '',
            qty: 1,
            unitPrice: 0,
            inRowSumPrice: 0
        }]);
        setDisCountPerc(0);
        setDisCountValue(0);
        setFooterSum(0);
        setVatValue(0);
        setTotlaValue(0);
        setVatReduce(false);
        setVatReduceValue(0);
        setCtmPay(0);
        setErrorHelp(prev => ({
            ...prev,
            company: false,
            docOwnerID: false,
            docManagerID: false,
            docDate: false,
            docDue: false,
        }));
        setInstallment(false);
        setInstallmentPerc(100);
        setInstallmentValue(0);
        setPageTitle(props.pageTitle);
        setPrefix(props.docPrefix);
        setApiPath(props.apiPath);
        setRefDocument(null);
        setRefDocumentID(null);
    }

    const setUpdateFrom = (v) => {
        setDocState(prev => ({
            ...prev,
            docNo: v.no,
            docTitle: v.project,
            docType: '',
            docStatus: v.status,
            docDesc: '',
            companyId: v.client,
            clientName: v.clientName,
            address: v.address,
            branch: v.branch,
            company: v.client,
            contactName: v.contact,
            email: v.email,
            phone: v.phone,
            tax: v.tax,
            docDate: v.date,
            docDue: v.dueDate,
            docOwnerID: v.owner,
            //docOwnerName: localStorage.getItem('name'),
            docManagerID: v.manager,
            docManagerName: '',
            docManagerNameObj: '',
            docRemark: v.remark,
            defualtVat: v.enableVat === '0' ? false : true,
        }));
        setInputFields(v.product);
        setDisCountPerc(v.discount_perc);
        setDisCountValue(v.discount_value);
        setFooterSum(v.sum);
        setVatValue(v.vat);
        setTotlaValue(v.total);
        let vatReduce = true;
        if (v.vat_reduce === '0') {
            vatReduce = false;
        }
        setVatReduce(vatReduce);
        setVatReduceValue(v.vat_reduce_value);
        setCtmPay(v.total_pay);
        setErrorHelp(prev => ({
            ...prev,
            company: false,
            docOwnerID: false,
            docManagerID: false,
            docDate: false,
            docDue: false,
        }));
        if (v.installmentPerc) {
            setInstallment(true);
        }
        setInstallmentPerc(v.installmentPerc);
        setInstallmentValue(v.installmentValue);
        setRefDocument(v.refDocument);
        setRefDocumentID(v.refDocumentID);
    }

    const handleClose = () => {
        clearState();
        setOpen(false);
    };

    const [users, setUsers] = useState([]);
    const fetchUser = (searchUser) => {
        axios({
            method: 'get',
            url: siteSetting.apiUrl + `/user?offset=0&keyword=${searchUser}`,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
        }).then(function (response) {
            setUsers(response.data.data);
        });
    }

    const [companies, setCompanies] = useState([]);
    const fetchCompanies = (searchCompany) => {
        axios({
            method: 'get',
            url: siteSetting.apiUrl + `/customer?offset=0&keyword=${searchCompany}`,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
        }).then(function (response) {
            setCompanies(response.data.data);
        });
    }

    const [products, setProducts] = useState([]);
    const fetchProducts = (searchProduct) => {
        axios({
            method: 'get',
            url: siteSetting.apiUrl + `/product?offset=0&keyword=${searchProduct}`,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
        }).then(function (response) {
            setProducts(response.data.data);
        });
    }

    const [errorHelp, setErrorHelp] = useState({
        company: false,
        docOwnerID: false,
        docManagerID: false,
        docDate: false,
        docDue: false,
    })

    const submitPost = () => {
        const requireCol = {
            company: 'บริษัท',
            docOwnerID: 'พนักงานขาย',
            docManagerID: 'ผู้อนุมัติ',
            docDate: 'วันที่',
            docDue: 'ครบกำหนด',
        };
        let errCount = 0;
        let errMsg = '';
        for (const [key, value] of Object.entries(requireCol)) {
            if (docstate[key] === undefined || docstate[key] === null || docstate[key] === '') {
                setErrorHelp(prev => ({
                    ...prev,
                    [key]: true,
                }));
                errCount++;
                errMsg = errMsg + value + ' ';
            }
        }

        if (errCount > 0) {
            setNotiMsg(prev => ({
                ...prev,
                msg: `กรุณากรอกข้อมูล${errMsg}`,
                type: 'error'
            }));
            setOpenNoti(true);
            return;
        }

        setDisabled(true);
        const sendData = {
            date: docstate.docDate,
            dueDate: docstate.docDue,
            project: docstate.docTitle,
            client: docstate.company,
            address: docstate.address,
            contact: docstate.contactName,
            phone: docstate.phone,
            email: docstate.email,
            tax: docstate.tax,
            branch: docstate.branch,
            owner: docstate.docOwnerID,
            manager: docstate.docManagerID,
            remark: docstate.docRemark,
            products: inputFields,
            sum: footerSum,
            discount_perc: disCountPerc,
            discount_value: disCountValue,
            vat: vatValue,
            total: totalValue,
            vat_reduce: vatReduce,
            vat_reduce_value: vatReduceValue,
            total_pay: ctmPay,
            installmentPerc: installmentPerc,
            installmentValue: installmentValue,
            refDocument: refDocument,
            refDocumentID: refDocumentID,
            enableVat: docstate.defualtVat
        }

        axios({
            method: actionType.actionMethod,
            url: siteSetting.apiUrl + apiPath + actionType.actionUrl,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
            data: sendData
        }).then(function (response) {
            setDisabled(false);
            if (response.data.status === 500) {
                setNotiMsg(prev => ({
                    ...prev,
                    msg: response.data.msg,
                    type: 'error'
                }));
                setOpenNoti(true);
            }
            if (response.data.status === 200) {
                setNotiMsg(prev => ({
                    ...prev,
                    msg: response.data.msg,
                    type: 'success'
                }));
                setOpenNoti(true);
                fetchData();
                setOpen(false);
                clearState();
                if (installment === true && apiPath !== props.apiPath) {
                    localStorage.setItem('notify', JSON.stringify({ msg: response.data.msg, type: 'success' }));
                    navigate(apiPath)
                }
            }
        });
    }


    useEffect(() => {
        var storageData = localStorage.getItem('notify');
        if (storageData) {
            storageData = JSON.parse(storageData);
            setNotiMsg(prev => ({
                ...prev,
                msg: storageData.msg,
                type: storageData.type
            }));
            setOpenNoti(true);
            localStorage.removeItem('notify');
        }
    }, []);


    var delayTimer;
    function sendSearch(text) {
        clearTimeout(delayTimer);
        delayTimer = setTimeout(function () {
            if (text.length > 2) {
                fetchCompanies(text)
            }
        }, 1000);
    }

    var delayTimer2;
    function sendSearch2(text) {
        clearTimeout(delayTimer2);
        delayTimer2 = setTimeout(function () {
            if (text.length > 2) {
                fetchUser(text)
            }
        }, 1000);
    }

    var delayTimer3;
    function sendSearch3(text) {
        clearTimeout(delayTimer3);
        delayTimer3 = setTimeout(function () {
            if (text.length > 2) {
                fetchProducts(text)
            }
        }, 1000);
    }

    useEffect(() => {
        document.title = `${pageTitle} - 8Content`;
        axios({
            method: 'get',
            url: siteSetting.apiUrl + apiPath + '?offset=0',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
        }).then(function (response) {
            if (response.data.status === 500 && response.data.msg === 'รูปแบบ Token ไม่ถูกต้อง') {
                window.location.href = '/logout';
            }
            setpageData(response.data);
            setPage(prev => ({
                ...prev,
                count: response.data.count,
                allpage: response.data.allpage,
                page: response.data.offset + 1
            }));
        });
        fetchCompanies('');
        fetchUser('');
        fetchProducts('');
    }, [apiPath, pageTitle]);

    const handleCompanyChange = (data) => {
        if (data) {
            setDocState(prev => ({
                ...prev,
                companyId: data.id,
                clientName: data.company,
                address: data.address,
                branch: data.branch,
                company: data.id,
                contactName: data.name,
                email: data.email,
                phone: data.phone,
                tax: data.tax,
            }));
        } else {
            setDocState(prev => ({
                ...prev,
                companyId: '',
                clientName: '',
                address: '',
                branch: '',
                company: '',
                contactName: '',
                email: '',
                phone: '',
                tax: '',
            }));
        }
    }

    const handleUserChange = (data) => {
        if (data) {
            setDocState(prev => ({
                ...prev,
                docOwnerID: data.id,
                docOwnerName: data.name,

            }));
        } else {
            setDocState(prev => ({
                ...prev,
                docOwnerID: localStorage.getItem('user'),
                docOwnerName: localStorage.getItem('name'),
            }));
        }
    }

    const handleManagerChange = (data) => {
        if (data) {
            setDocState(prev => ({
                ...prev,
                docManagerID: data.id,
                docManagerName: data.name,
                docManagerNameObj: '',
            }));
        } else {
            setDocState(prev => ({
                ...prev,
                docManagerID: '',
                docManagerName: '',
                docManagerNameObj: '',
            }));
        }
    }

    const handleProductChange = (index, data) => {
        const list = [...inputFields];
        list[index].productID = data.id;
        list[index].productSKU = data.sku;
        list[index].productDescription = data.name + "\n" + data.desc;
        list[index].qty = 1;
        list[index].unitPrice = parseInt(removeCommaFromInputFields(data.price));
        list[index].inRowSumPrice = parseInt(removeCommaFromInputFields(data.price)) * 1;
        setInputFields(list);
        calFooterSum();
    }

    const [inputFields, setInputFields] = useState([{
        productID: '',
        productSKU: '',
        productDescription: '',
        qty: 1,
        unitPrice: 0,
        inRowSumPrice: 0
    }]);

    const addInputField = () => {
        setInputFields([...inputFields, {
            productID: '',
            productSKU: '',
            productDescription: '',
            qty: 1,
            unitPrice: 0,
            inRowSumPrice: 0
        }])
    }
    const removeInputFields = (index) => {
        const rows = [...inputFields];
        rows.splice(index, 1);
        setInputFields(rows);
        calFooterSum();
    }


    const removeCommaFromInputFields = (val) => {
        //console.log(val);
        return val !== '' ? String(val).replace(/,/g, '') : val;
        //return val.replace(/,/g, '')
    }

    const handleChange = (index, evnt) => {
        const { name, value } = evnt.target;

        //const sanitizedValue = name === 'qty' || 'unitPrice' ? value.replace(/,/g, '') : value;

        const list = [...inputFields];
        list[index][name] = value;

        if (name === 'qty') {
            list[index].inRowSumPrice = removeCommaFromInputFields(list[index].unitPrice) * removeCommaFromInputFields(value);
        }
        if (name === 'unitPrice') {
            list[index].inRowSumPrice = removeCommaFromInputFields(value) * removeCommaFromInputFields(list[index].qty);
        }

        setInputFields(list);
        calFooterSum();
    }

    const [disCountPerc, setDisCountPerc] = useState(0);
    const [disCountValue, setDisCountValue] = useState(0);
    const [footerSum, setFooterSum] = useState(0);
    const [vatValue, setVatValue] = useState(0);
    const [totalValue, setTotlaValue] = useState(0);
    const [vatReduce, setVatReduce] = useState(false);
    const [vatReduceValue, setVatReduceValue] = useState(0);
    const [ctmPay, setCtmPay] = useState(0);

    const calFooterSum = () => {
        let sum = 0;
        let vat = 0;
        let total = 0;
        let clientPayV = 0;
        let clientPay = 0;
        for (let i = 0; i < inputFields.length; i++) {
            sum += parseInt(removeCommaFromInputFields(inputFields[i].inRowSumPrice));
        }
        setFooterSum(sum);

        if (disCountPerc > 1) {
            let dc = (footerSum * disCountPerc) / 100
            setDisCountValue(dc)
        }

        if (installment === true) {
            let install = ((footerSum - disCountValue) * installmentPerc) / 100
            setInstallmentValue(install);
        }

        if (installmentPerc < 100) {
            if (sum > 0) {
                vat = (installmentValue * 7) / 100
                total = installmentValue + vat
                setVatValue(vat)
                setTotlaValue(total)
            }
            if (vatReduce === true) {
                clientPayV = (installmentValue * 3) / 100
                setVatReduceValue(clientPayV);
                clientPay = total - clientPayV
                setCtmPay(clientPay)
            } else {
                setVatReduceValue(0);
                setCtmPay(0)
            }
        } else {
            if (sum > 0) {
                vat = ((footerSum - disCountValue) * 7) / 100
                total = (sum - disCountValue) + (((footerSum - disCountValue) * 7) / 100)
                setVatValue(vat)
                setTotlaValue(total)
            }
            if (vatReduce === true) {
                clientPayV = ((footerSum - disCountValue) * 3) / 100
                setVatReduceValue(clientPayV);
                clientPay = total - clientPayV
                setCtmPay(clientPay)
            } else {
                setVatReduceValue(0);
                setCtmPay(0)
            }
        }
    }


    useEffect(() => {
        let sum = 0;
        let vat = 0;
        let total = 0;
        let clientPayV = 0;
        let clientPay = 0;
        for (let i = 0; i < inputFields.length; i++) {
            sum += parseInt(removeCommaFromInputFields(inputFields[i].inRowSumPrice));
        }
        setFooterSum(sum);

        if (disCountPerc > 1) {
            let dc = (footerSum * disCountPerc) / 100
            setDisCountValue(dc)
        }

        if (installment === true) {
            let install = ((footerSum - disCountValue) * installmentPerc) / 100
            setInstallmentValue(install);
        }

        if (installmentPerc < 100) {
            if (sum > 0) {
                vat = (installmentValue * 7) / 100
                total = installmentValue + vat
                setVatValue(vat)
                setTotlaValue(total)
            }
            if (vatReduce === true) {
                clientPayV = (installmentValue * 3) / 100
                setVatReduceValue(clientPayV);
                clientPay = total - clientPayV
                setCtmPay(clientPay)
            } else {
                setVatReduceValue(0);
                setCtmPay(0)
            }
        } else {
            if (sum > 0) {
                vat = ((footerSum - disCountValue) * 7) / 100
                total = (sum - disCountValue) + (((footerSum - disCountValue) * 7) / 100)
                setVatValue(vat)
                setTotlaValue(total)
            }
            if (vatReduce === true) {
                clientPayV = ((footerSum - disCountValue) * 3) / 100
                setVatReduceValue(clientPayV);
                clientPay = total - clientPayV
                setCtmPay(clientPay)
            } else {
                setVatReduceValue(0);
                setCtmPay(0)
            }
        }

    }, [disCountPerc, disCountValue, inputFields, footerSum, vatReduce, installment, installmentPerc, installmentValue]);

    const submitSearch = (e) => {
        e.preventDefault();
        var searchText = e.target[1].value;
        if (searchText.length < 3) {
            setNotiMsg(prev => ({
                ...prev,
                msg: 'กรุณาระบบคำค้นหามากกว่า 2 ตัวอักษร',
                type: 'error'
            }));
            setOpenNoti(true);
        } else {
            fetchData(0, searchText)
        }
    }

    const searchTextChange = (text) => {
        if (text.length === 0) {
            fetchData(0)
        }
    }

    const [notiCount, setNotiCount] = useState(parseInt(localStorage.getItem('noticount')));

    useEffect(() => {
        const interval = setInterval(() => {
            if (parseInt(localStorage.getItem('noticount')) !== notiCount) {
                setNotiCount(parseInt(localStorage.getItem('noticount')))
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [notiCount]);

    function closePrint() {
        document.body.removeChild(this.__container__);
        /*
        document.querySelectorAll('iframe').forEach(function (elem) {
            elem.parentNode.removeChild(elem);
        });
        */
    }

    function setPrint() {
        this.contentWindow.__container__ = this;
        this.contentWindow.onbeforeunload = closePrint;
        this.contentWindow.onafterprint = closePrint;
        this.contentWindow.focus();
        this.contentWindow.print();
    }

    function printPage(sURL) {
        var oHiddFrame = document.createElement("iframe");
        oHiddFrame.onload = setPrint;
        oHiddFrame.style.position = "fixed";
        oHiddFrame.style.right = "0";
        oHiddFrame.style.bottom = "0";
        oHiddFrame.style.width = "0";
        oHiddFrame.style.height = "0";
        oHiddFrame.style.border = "0";
        oHiddFrame.src = sURL;
        document.body.appendChild(oHiddFrame);
    }

    const [loadingBtn, setLoadingBtn] = useState(false);

    const [openCopyOrOriginal, setOpenCopyOrOriginal] = useState(false);
    const [copyOrOriginal, setCopyOrOriginal] = useState(1);
    const [copyOrOriginalDialog, setCopyOrOriginalDialog] = useState({
        title: 'ดาวน์โหลด',
        tagline: '',
        button: 'ดาวน์โหลด'
    });
    const [pdfState, setPDFState] = useState({
        id: '',
        fileName: '',
        action: '',
    });

    const downloadReal = (id, fileName, action, params = '') => {
        setLoadingBtn(id + action);
        setDisabled(true);
        axios({
            method: 'get',
            url: `${siteSetting.docUrl}${apiPath}/${id}/download${params}`,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
            responseType: 'blob'
        }).then(function (response) {
            setLoadingBtn(false);
            setDisabled(false);
            if (action === 'download') {
                const a = document.createElement('a');
                a.href = window.URL.createObjectURL(response.data);
                a.download = `${fileName}.pdf`;
                document.body.appendChild(a);
                a.click();
                a.remove();
                setOpenCopyOrOriginal(false)
            }
            if (action === 'print') {
                printPage(URL.createObjectURL(response.data));
                setOpenCopyOrOriginal(false)
            }
        });
    }

    const downloadPDF = (id, fileName, action) => {
        if (apiPath === '/quotation') {
            downloadReal(id, fileName, action)
        } else if (apiPath === '/purchase-orders') {
            downloadReal(id, fileName, action)
        } else if (apiPath === '/purchases') {
            downloadReal(id, fileName, action)
        } else {
            setOpenCopyOrOriginal(true);
            if (action === 'print') {
                setCopyOrOriginalDialog(prev => ({
                    ...prev,
                    title: `เลือกรูปแบบการพิมพ์เอกสาร`,
                    tagline: fileName,
                    button: 'พิมพ์'
                }));
            }
            if (action === 'download') {
                setCopyOrOriginalDialog(prev => ({
                    ...prev,
                    title: `เลือกรูปแบบการดาวน์โหลดเอกสาร`,
                    tagline: fileName,
                    button: 'ดาวน์โหลด'
                }));
            }
        }
    }

    const handleDownloadOrPrint = () => {
        downloadReal(pdfState.id, pdfState.fileName, pdfState.action, `?type=${copyOrOriginal}`);
    }

    const cancelDoc = (id, status) => {
        axios({
            method: 'put',
            url: siteSetting.apiUrl + apiPath + '/status/' + id + '/' + status,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
        }).then(function (response) {
            if (response.data.status === 500) {
                setNotiMsg(prev => ({
                    ...prev,
                    msg: response.data.msg,
                    type: 'error'
                }));
                setOpenNoti(true);
            }
            if (response.data.status === 200) {
                setNotiMsg(prev => ({
                    ...prev,
                    msg: response.data.msg,
                    type: 'success'
                }));
                setOpenNoti(true);
                fetchData();
                setOpen(false);
                //clearState();
            }
        });
    }

    const [openCollectMoney, setOpenCollectMoney] = useState(false);
    const [collectMoneyDate, setOpenMoneyDate] = useState(dayjs(new Date()).format('YYYY/MM/DD'));
    const [collectMoneyMethod, setCollectMoneyMethod] = useState(3);
    const collectMoney = () => {
        setDisabled(true);
        axios({
            method: 'put',
            url: siteSetting.apiUrl + apiPath + '/collect/' + actionID,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
            data: {
                method: collectMoneyMethod,
                date: collectMoneyDate
            }
        }).then(function (response) {
            setDisabled(false);
            if (response.data.status === 500) {
                setNotiMsg(prev => ({
                    ...prev,
                    msg: response.data.msg,
                    type: 'error'
                }));
                setOpenNoti(true);
            }
            if (response.data.status === 200) {
                setNotiMsg(prev => ({
                    ...prev,
                    msg: response.data.msg,
                    type: 'success'
                }));
                setOpenNoti(true);
                fetchData();
                setOpen(false);
                setOpenCollectMoney(false)
                //clearState();
            }
        });
    }

    const statusAction = (status, id, docNo) => {
        switch (status) {
            case 0:
                cancelDoc(id, 0);
                fetchData();
                break;
            case 1:
                setPageTitle('ใบวางบิล/ใบแจ้งหนี้');
                setPrefix('BL');
                setApiPath('/invoice');
                setActionType(prev => ({
                    ...prev,
                    actionBtn: 'สร้าง',
                    actionUrl: '/addnew',
                    actionMethod: 'post'
                }));
                setInstallment(true);
                setRefDocument(docNo);
                setRefDocumentID(id)
                handleClickOpen();
                getLastQuote('/invoice');
                break;
            case 2:
                cancelDoc(id, 2);
                fetchData();
                break;
            case 3:
                setPageTitle('ใบกำกับภาษี/ใบเสร็จรับเงิน');
                setPrefix('INV');
                setApiPath('/receipt');
                setActionType(prev => ({
                    ...prev,
                    actionBtn: 'สร้าง',
                    actionUrl: '/addnew',
                    actionMethod: 'post'
                }));
                //setInstallment(true);
                setRefDocument(docNo);
                setRefDocumentID(id)
                handleClickOpen();
                getLastQuote('/receipt');
                break;
            case 4:
                setOpenCollectMoney(true);
                break;
            case 5:
                setPageTitle('ใบรับสินค้า');
                setPrefix('RI');
                setApiPath('/purchases');
                setActionType(prev => ({
                    ...prev,
                    actionBtn: 'สร้าง',
                    actionUrl: '/addnew',
                    actionMethod: 'post'
                }));
                //setInstallment(true);
                setRefDocument(docNo);
                setRefDocumentID(id)
                handleClickOpen();
                getLastQuote('/purchases');
                break;
            default:
                setPageTitle(props.pageTitle);
                setInstallment(false);
        }
    }

    const statusColor = (status) => {
        switch (status) {
            case '0':
                return {
                    color: '#1f2937',
                    bg: '#f3f4f6',
                };
            case '1':
                return {
                    color: '#1f2937',
                    bg: '#e0e7ff',
                };
            case '2':
                return {
                    color: '#1f2937',
                    bg: '#e5e7eb',
                };
            case '3':
                return {
                    color: '#1f2937',
                    bg: '#e0e7ff',
                };
            case '4':
                return {
                    color: '#1f2937',
                    bg: '#e0e7ff',
                };
            case '5':
                return {
                    color: '#1f2937',
                    bg: '#e0e7ff',
                };
            case '6':
                return {
                    color: '#1f2937',
                    bg: '#e0e7ff',
                };
            default:
                return {
                    color: '#1f2937',
                    bg: '#f3f4f6',
                };
        }
    }

    const handleSort = () => {
        let stockItems = [...inputFields];
        const draggedItemsContent = stockItems.splice(dragItem.current, 1)[0];
        stockItems.splice(dragOverItem.current, 0, draggedItemsContent);
        dragItem.current = null;
        dragOverItem.current = null;
        setInputFields(stockItems);
    }

    const [isDraggable, setIsDraggable] = useState(true);


    return (
        <>
            <div className="app-layout-modern flex flex-auto flex-col">
                <div className="flex flex-auto min-w-0">
                    <SideNav />
                    <div className="flex flex-col flex-auto min-h-screen min-w-0 relative w-full bg-main-bg-color justify-between max-h-screen bg-slate-50">
                        <AppHeader
                            pageTitle={pageTitle}
                            submitSearch={submitSearch}
                            searchTextChange={searchTextChange}
                            notiCount={notiCount}
                            newMsg={props.newMsg}
                        />
                        <div className="relative overflow-auto px-7 py-5 mb-auto" id="main-content">
                            <div className='flex items-center justify-between'>
                                <div className='flex gap-4 items-center'>
                                    <h1 className='text-xl font-bold'>{pageTitle}</h1>
                                </div>
                                <div className='flex gap-2'>
                                    <Button variant="contained" className='flex gap-2 items-center' style={{ alignItems: 'center' }}
                                        onClick={() => {
                                            handleClickOpen();
                                            setActionType(prev => ({
                                                ...prev,
                                                actionBtn: 'สร้าง',
                                                actionUrl: '/addnew',
                                                actionMethod: 'post'
                                            }));
                                            getLastQuote(apiPath);
                                            clearState();
                                        }}>
                                        <AddOutlinedIcon />
                                        Add new
                                    </Button>
                                </div>
                            </div>
                            <div className='table-responsive mt-7 min-h-80 bg-white'>
                                <table className="table-auto w-full border-collapse border border-slate-150">
                                    <thead>
                                        <tr>
                                            <th className='text-left border-b border-slate-150 p-2 pl-8 pt-3 pb-3 font-bold'>วันที่</th>
                                            <th className='text-left border-b border-slate-150 p-2 pl-8 pt-3 pb-3 font-bold'>เลขที่เอกสาร</th>
                                            <th className='text-left border-b border-slate-150 p-2 pl-8 pt-3 pb-3 font-bold'>
                                                {pageTitle === "ใบสั่งซื้อ" || pageTitle === "ใบรับสินค้า" || pageTitle === "ค่าใช้จ่าย" ? 'ผู้จำหน่าย/โปรเจ็ค' : 'ลูกค้า/โปรเจ็ค'}
                                            </th>
                                            <th className='border-b border-slate-150 p-2 pl-8 pt-3 pb-3 font-bold text-right'>ยอดรวม</th>
                                            <th className='border-b border-slate-150 p-2 pl-8 pt-3 pb-3 font-bold text-left'>สถานะ</th>
                                            <th className='text-right border-b border-slate-150 p-2 pl-8 pt-3 pb-3 font-bold'>จัดการ</th>
                                        </tr>
                                    </thead>
                                    <tbody id='mainTable' className="border-t-2 border-slate-200">
                                        {page.count > 0 && pageData !== null && Object.entries(pageData.data).map(([kk, itms]) => (
                                            <tr className="align-baseline border-b border-b-slate-150" key={kk}>
                                                <td className='p-2 pl-8'>{itms.date}</td>
                                                <td className='p-2 pl-8'>{props.docPrefix}{itms.no}</td>
                                                <td className='p-2 pl-8'>
                                                    <p className="font-medium">{itms.clientName}</p>
                                                    <p className="text-xs">{itms.project}</p>
                                                </td>
                                                <td className='p-2 pl-8 text-right'>{fixedNUM(itms.total, 2)}</td>
                                                <td className='p-0 pl-8'>
                                                    {props.statusMenu && <FormControl fullWidth variant="standard" sx={{ padding: 0, marginTop: '-3px' }}>
                                                        <Select
                                                            sx={{ backgroundColor: statusColor(itms.status).bg, color: statusColor(itms.status).color, padding: '0 0.5em 0 1em', maxWidth: '220px', borderRadius: '5px', fontSize: '0.9em' }}
                                                            value={itms.status}
                                                            onChange={(event) => {
                                                                setActionID(itms.id);
                                                                setUpdateFrom(pageData.data[kk]);
                                                                statusAction(event.target.value, itms.id, props.docPrefix + itms.no);
                                                                setDocState(prev => ({
                                                                    ...prev,
                                                                    docDate: dayjs(new Date()).format('YYYY/MM/DD'),
                                                                    docDue: dayjs(new Date()).format('YYYY/MM/DD')
                                                                }));
                                                            }}
                                                        >
                                                            {Object.entries(props.statusMenu).map(([kkk, iii]) => (
                                                                <MenuItem key={kkk} value={iii.value}>{iii.label}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>}
                                                </td>
                                                <td className='p-4 flex items-center space-x-1 justify-end'>
                                                    {pageTitle !== "ค่าใช้จ่าย" && <button type="button" className='py-0 px-2'
                                                        onClick={() => {
                                                            downloadPDF(itms.id, `${pageTitle} - ${props.docPrefix}${itms.no} - ${itms.clientName}`, 'print');
                                                            setPDFState(prev => ({
                                                                ...prev,
                                                                id: itms.id,
                                                                fileName: `${pageTitle} - ${props.docPrefix}${itms.no} - ${itms.clientName}`,
                                                                action: 'print',
                                                            }));
                                                        }}>
                                                        {loadingBtn === (itms.id + 'print') ? <RestartAltOutlinedIcon sx={{ animation: "spin 2s linear infinite", "@keyframes spin": { "0%": { transform: "rotate(360deg)", }, "100%": { transform: "rotate(0deg)", }, }, }} /> : <LocalPrintshopOutlinedIcon />}
                                                    </button>}
                                                    {pageTitle !== "ค่าใช้จ่าย" && <button type="button" className='py-0 px-2'
                                                        onClick={() => {
                                                            downloadPDF(itms.id, `${pageTitle} - ${props.docPrefix}${itms.no} - ${itms.clientName}`, 'download');
                                                            setPDFState(prev => ({
                                                                ...prev,
                                                                id: itms.id,
                                                                fileName: `${pageTitle} - ${props.docPrefix}${itms.no} - ${itms.clientName}`,
                                                                action: 'download',
                                                            }));
                                                        }}>
                                                        {loadingBtn === (itms.id + 'download') ? <RestartAltOutlinedIcon sx={{ animation: "spin 2s linear infinite", "@keyframes spin": { "0%": { transform: "rotate(360deg)", }, "100%": { transform: "rotate(0deg)", }, }, }} /> : <FileDownloadOutlinedIcon />}
                                                    </button>}
                                                    <button type="button" className='py-0 px-2'
                                                        onClick={() => {
                                                            setActionType(prev => ({
                                                                ...prev,
                                                                actionBtn: 'แก้ไข',
                                                                actionUrl: '/update/' + itms.id,
                                                                actionMethod: 'put'
                                                            }));
                                                            handleClickOpen();
                                                            setActionID(itms.id);
                                                            setUpdateFrom(pageData.data[kk]);
                                                        }}>
                                                        <CreateOutlinedIcon />
                                                    </button>
                                                    <button type="button" className='py-0 px-2'
                                                        onClick={() => {
                                                            setOpenAlert(true);
                                                            setActionID(itms.id);
                                                        }}>
                                                        <DeleteForeverOutlinedIcon />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                        {page.count === '0' &&
                                            <tr>
                                                <td className='p-4'>ยังไม่มีรายการ</td>
                                            </tr>
                                        }
                                        {pageData === null &&
                                            <PageSKT tr='20' td='5' />
                                        }
                                    </tbody>
                                </table>


                            </div>
                        </div>
                        <div className='px-7 py-3 sticky bottom-0  flex justify-between'>
                            {<><div>ทั้งหมด {page.count} รายการ</div>
                                <Pagination count={page.allpage} page={page.page} onChange={handlePageChange} onClick={() => { document.getElementById('main-content').scrollTo({ top: 0, behavior: 'smooth' }); }} /></>}
                        </div>
                    </div>
                </div>
            </div>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <DialogTitle className="border-b border-b-slate-200">
                    <div className="flex justify-between items-center">
                        <div>
                            {actionType.actionBtn + pageTitle} {prefix + docstate.docNo}
                            {refDocument && <small className="pl-4">อ้างอิง  {refDocument}</small>}
                        </div>
                        <div className="flex gap-4">
                            <Button variant="outlined" onClick={handleClose}>ปิดหน้าต่าง</Button>
                            <Button variant="contained" onClick={submitPost} disabled={disabled}>
                                {actionType.actionBtn + pageTitle}
                                {disabled && <CircularProgress size="1rem" />}
                            </Button>
                            <button onClick={handleClose}>
                                <CloseOutlinedIcon />
                            </button>
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent className="bg-white">
                    <div className='flex mt-8 px-4'>
                        <div className='w-7/12 mb-4'>
                            <div className='grid grid-cols-2'>
                                <div>
                                    <Autocomplete
                                        className="mt-0.5"
                                        disablePortal
                                        id="doc-company"
                                        options={companies}
                                        freeSolo
                                        sx={{ width: '100%', padding: '10px' }}
                                        getOptionLabel={(option) => option.company}
                                        isOptionEqualToValue={(option, value) => option.company === value.company}
                                        renderOption={(props, option) => (
                                            <Box component="li" {...props} key={option.id}>
                                                {option.company}
                                            </Box>
                                        )}
                                        value={docstate.clientName ? { company: docstate.clientName } : null}
                                        onChange={(e, v) => {
                                            handleCompanyChange(v)
                                            if (v.id) {
                                                setErrorHelp(prev => ({
                                                    ...prev,
                                                    company: false,
                                                }));
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                error={errorHelp.company}
                                                helperText={errorHelp.company && 'กรุณาเลือกบริษัท'}
                                                label={pageTitle === "ใบสั่งซื้อ" || pageTitle === "ใบรับสินค้า" || pageTitle === "ค่าใช้จ่าย" ? 'ผู้จำหน่าย' : 'ลูกค้า'}
                                                variant="standard"
                                                placeholder="พิมพ์เพื่อค้นหาผู้ติดต่อ / บริษัท"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password',
                                                    form: {
                                                        autocomplete: 'off',
                                                    },
                                                }}
                                                onKeyUp={(e) => sendSearch(e.target.value)}
                                            />
                                        )}
                                    />
                                    <div className='px-3 grid'>
                                        <TextField
                                            label="ที่อยู่"
                                            variant="standard"
                                            multiline
                                            rows={4}
                                            onChange={(e) =>
                                                setDocState(prev => ({
                                                    ...prev,
                                                    address: e.target.value,
                                                }))
                                            }
                                            value={docstate.address !== null && docstate.address !== undefined ? docstate.address : ''}
                                            id="address" autoComplete="address"
                                        />
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <div className="mx-3 mb-4">
                                        <TextField
                                            className="w-full"
                                            label="ชื่อผู้ติดต่อ"
                                            variant="standard"
                                            id="contactName"
                                            name="contactName"
                                            type="text"
                                            autoComplete="contactName"
                                            placeholder="ข้อมูลผู้ติดต่อ"
                                            onChange={(e) =>
                                                setDocState(prev => ({
                                                    ...prev,
                                                    contactName: e.target.value,
                                                }))
                                            }
                                            value={docstate.contactName !== null && docstate.contactName !== undefined ? docstate.contactName : ''}
                                        />
                                    </div>
                                    <div className='grid grid-cols-2 gap-4 px-3'>
                                        <TextField
                                            label="เบอร์โทร"
                                            variant="standard"
                                            id="phone"
                                            name="phone"
                                            type="text"
                                            autoComplete="phone"
                                            placeholder="เบอร์โทร"
                                            onChange={(e) =>
                                                setDocState(prev => ({
                                                    ...prev,
                                                    phone: e.target.value,
                                                }))
                                            }
                                            value={docstate.phone !== null && docstate.phone !== undefined ? docstate.phone : ''}
                                        />
                                        <TextField
                                            label="อีเมล"
                                            variant="standard"
                                            id="email"
                                            name="email"
                                            type="text"
                                            autoComplete="email"
                                            placeholder="อีเมล"
                                            onChange={(e) =>
                                                setDocState(prev => ({
                                                    ...prev,
                                                    email: e.target.value,
                                                }))
                                            }
                                            value={docstate.email !== null && docstate.email !== undefined ? docstate.email : ''}
                                        />
                                    </div>
                                    <div className='grid grid-cols-2 gap-4 px-3 mt-4'>
                                        <TextField
                                            label="เลขผู้เสียภาษี"
                                            variant="standard"
                                            id="tax"
                                            name="tax"
                                            type="number"
                                            autoComplete="tax"
                                            placeholder="เลขผู้เสียภาษี"
                                            onChange={(e) =>
                                                setDocState(prev => ({
                                                    ...prev,
                                                    tax: e.target.value,
                                                }))
                                            }
                                            value={docstate.tax !== null && docstate.tax !== undefined ? docstate.tax : ''}
                                        />
                                        <TextField
                                            label="สาขา"
                                            variant="standard"
                                            id="branch"
                                            name="branch"
                                            type="text"
                                            autoComplete="branch"
                                            placeholder="สาขา"
                                            onChange={(e) =>
                                                setDocState(prev => ({
                                                    ...prev,
                                                    branch: e.target.value,
                                                }))
                                            }
                                            value={docstate.branch}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-5/12 mb-4 pl-4">
                            <div className='grid grid-cols-2'>
                                <div className="mt-3 mx-3">
                                    <div className="mt-0.5 mb-4">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                renderInput={(props) =>
                                                    <TextField
                                                        {...props}
                                                        variant="standard"
                                                        fullWidth
                                                        error={errorHelp.docDate}
                                                        helperText={errorHelp.docDate && 'กรุณาเลือกวันที่เอกสาร'}
                                                    />
                                                }
                                                ampm={false}
                                                label="วันที่"
                                                inputFormat="YYYY/MM/DD"
                                                value={docstate.docDate || dayjs(new Date()).format('YYYY/MM/DD')}
                                                onChange={(newValue) => {
                                                    if (newValue !== null && newValue.$d !== 'Invalid Date') {
                                                        setDocState(prev => ({
                                                            ...prev,
                                                            docDate: dayjs(newValue.$d).format('YYYY/MM/DD')
                                                        }));
                                                        setErrorHelp(prev => ({
                                                            ...prev,
                                                            docDate: false,
                                                        }));
                                                    }
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                    <div className="mb-4">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                renderInput={(props) =>
                                                    <TextField
                                                        {...props}
                                                        variant="standard"
                                                        fullWidth
                                                        error={errorHelp.docDue}
                                                        helperText={errorHelp.docDue && 'กรุณาเลือกวันครบกำหนด'}
                                                    />
                                                }
                                                ampm={false}
                                                label="ครบกำหนด"
                                                inputFormat="YYYY/MM/DD"
                                                value={docstate.docDue || dayjs(new Date()).format('YYYY/MM/DD')}
                                                onChange={(newValue) => {
                                                    if (newValue !== null && newValue.$d !== 'Invalid Date') {
                                                        setDocState(prev => ({
                                                            ...prev,
                                                            docDue: dayjs(newValue.$d).format('YYYY/MM/DD')
                                                        }));
                                                        setErrorHelp(prev => ({
                                                            ...prev,
                                                            docDue: false,
                                                        }));
                                                    }
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </div>
                                <div className="mt-3 mx-3">
                                    <div className="mb-4">
                                        <Autocomplete
                                            className="mt-0.5"
                                            disablePortal
                                            id="doc-owner"
                                            options={users}
                                            sx={{ width: '100%', padding: '0px' }}
                                            getOptionLabel={(option) => option.name}
                                            renderOption={(props, option) => (
                                                <Box component="li" {...props} key={option.id}>
                                                    {option.name}
                                                </Box>
                                            )}
                                            onChange={(e, v) => {
                                                handleUserChange(v)
                                                if (v.id) {
                                                    setErrorHelp(prev => ({
                                                        ...prev,
                                                        docOwnerID: false,
                                                    }));
                                                }
                                            }}
                                            value={docstate.docOwnerID ? users.find(({ id }) => id === docstate.docOwnerID) : null}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    error={errorHelp.docOwnerID}
                                                    helperText={errorHelp.docOwnerID && 'กรุณาเลือกพนักงานขาย'}
                                                    label="พนักงานขาย"
                                                    variant="standard"
                                                    placeholder="พิมพ์เพื่อค้นหา"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'new-password',
                                                        form: {
                                                            autocomplete: 'off',
                                                        },
                                                    }}
                                                    onKeyUp={(e) => sendSearch2(e.target.value)}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <Autocomplete
                                            className="mt-0.5"
                                            disablePortal
                                            id="doc-owner"
                                            options={users}
                                            sx={{ width: '100%', padding: '0px' }}
                                            getOptionLabel={(option) => option.name}
                                            renderOption={(props, option) => (
                                                <Box component="li" {...props} key={option.id}>
                                                    {option.name}
                                                </Box>
                                            )}
                                            onChange={(e, v) => {
                                                handleManagerChange(v)
                                                if (v.id) {
                                                    setErrorHelp(prev => ({
                                                        ...prev,
                                                        docManagerID: false,
                                                    }));
                                                }
                                            }}
                                            value={docstate.docManagerID ? users.find(({ id }) => id === docstate.docManagerID) : null}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    error={errorHelp.docManagerID}
                                                    helperText={errorHelp.docManagerID && 'กรุณาเลือกผู้อนุมัติ'}
                                                    label="ผู้อนุมัติ"
                                                    variant="standard"
                                                    placeholder="พิมพ์เพื่อค้นหา"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'new-password',
                                                        form: {
                                                            autocomplete: 'off',
                                                        },
                                                    }}
                                                    onKeyUp={(e) => sendSearch2(e.target.value)}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={installment ? 'grid grid-cols-2' : 'grid grid-cols-1'}>
                                <div className="mb-4 mx-3">
                                    <TextField
                                        label="ชื่อโปรเจค"
                                        className="w-full"
                                        variant="standard"
                                        id="ProjectName"
                                        name="ProjectName"
                                        type="text"
                                        autoComplete="ProjectName"
                                        placeholder="ชื่อโปรเจค"
                                        onChange={(e) =>
                                            setDocState(prev => ({
                                                ...prev,
                                                docTitle: e.target.value,
                                            }))
                                        }
                                        value={docstate.docTitle !== null && docstate.docTitle !== undefined ? docstate.docTitle : ''}
                                    />
                                </div>

                                {installment &&
                                    <div className="mb-4 mx-3">


                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel htmlFor='installment'>จำนวน % การชำระ</InputLabel>
                                            <Input
                                                id='installment'
                                                type="number"
                                                endAdornment={
                                                    <InputAdornment position="end">%</InputAdornment>
                                                }
                                                label="จำนวน % การชำระ"
                                                fullWidth
                                                variant="standard"
                                                onChange={(e) => setInstallmentPerc(e.target.value)}
                                                value={installmentPerc && installmentPerc}
                                            />
                                        </FormControl>


                                    </div>
                                }
                            </div>

                        </div>
                    </div>
                    <div className="mt-12 bg-white p-8 rounded">
                        <table>
                            <thead>
                                <tr className="border-b border-b-slate-200">
                                    <th className=""></th>
                                    <th className="text-center w-4 pb-3 px-3">ลำดับ</th>
                                    <th className="text-left pb-3">รหัสสินค้า</th>
                                    <th className="w-7/12 text-left pb-3">ชื่อสินค้า / รายละเอียด</th>
                                    <th className="w-1/12 text-center pb-3 pr-2">จำนวน</th>
                                    <th className="w-1/12 text-right pb-3 pr-6">ราคาต่อหน่วย</th>
                                    <th className="w-1/12 text-right pb-3 pr-6">ราคารวม</th>
                                    <th className=""></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    inputFields.map((data, index) => {
                                        return (
                                            <tr
                                                className="align-baseline border-b border-b-slate-200"
                                                key={index}
                                                draggable={isDraggable}
                                                onDragStart={(e) => (dragItem.current = index)}
                                                onDragEnter={(e) => (dragOverItem.current = index)}
                                                onDragEnd={handleSort}
                                                onDragOver={(e) => e.preventDefault()}
                                            >
                                                <td className="text-center px-2 py-4 pb-5 align-top cursor-grab">
                                                    <DragHandleOutlinedIcon />
                                                </td>
                                                <td className="text-center px-2 py-4"><span style={{ verticalAlign: '-webkit-baseline-middle' }}>{index + 1}</span></td>
                                                <td className="px-2 py-4 pl-4">
                                                    <Autocomplete
                                                        //disablePortal
                                                        options={products}
                                                        sx={{ width: '100%', padding: '0px' }}
                                                        getOptionLabel={(option) => option.sku}
                                                        //getOptionLabel={(option) => typeof option.sku === 'undefined' ? '<undefined>': `${data.productSKU}`}
                                                        isOptionEqualToValue={(option, value) => option.sku === value.sku}
                                                        //isOptionEqualToValue={(option, value) => option.sku === option.sku}
                                                        //val { sku: data.productSKU }
                                                        renderOption={(props, option) => (
                                                            <Box component="li" {...props} key={option.id}>
                                                                {option.sku}
                                                            </Box>
                                                        )}
                                                        onChange={(e, v) => handleProductChange(index, v)}

                                                        value={data.productSKU ? { sku: data.productSKU } : null}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="standard"
                                                                placeholder="พิมพ์เพื่อค้นหารหัสสินค้า"
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                    autoComplete: 'new-password',
                                                                    form: {
                                                                        autocomplete: 'off',
                                                                    },
                                                                }}
                                                                onKeyUp={(e) => sendSearch3(e.target.value)}
                                                                onMouseDown={() => setIsDraggable(false)}
                                                                onMouseUp={() => setIsDraggable(true)}
                                                            />
                                                        )}
                                                    />
                                                </td>
                                                <td className="px-2 py-4">
                                                    <TextField
                                                        className="w-full"
                                                        placeholder="ชื่อสินค้า / รายละเอียด"
                                                        multiline
                                                        variant="standard"
                                                        name="productDescription"
                                                        type="text"
                                                        onChange={(evnt) => { handleChange(index, evnt) }}
                                                        value={data.productDescription}
                                                        onMouseDown={() => setIsDraggable(false)}
                                                        onMouseUp={() => setIsDraggable(true)}
                                                    />
                                                </td>
                                                <td className="px-2 py-4">
                                                    {/*<TextField
                                                        className="w-full"
                                                        placeholder="จำนวน"
                                                        inputProps={{ min: 0, style: { textAlign: 'center' } }}
                                                        variant="standard"
                                                        name="qty"
                                                        type="number"
                                                        onChange={(evnt) => { handleChange(index, evnt) }}
                                                        value={data.qty}
                                                    />*/}
                                                    <NumericFormat
                                                        thousandSeparator=","
                                                        className="border-0 outline-none w-full text-center"
                                                        placeholder="จำนวน"
                                                        name="qty"
                                                        onChange={(evnt) => {
                                                            handleChange(index, evnt)
                                                        }}
                                                        value={data.qty}
                                                        onMouseDown={() => setIsDraggable(false)}
                                                        onMouseUp={() => setIsDraggable(true)}
                                                    />
                                                </td>
                                                <td className="px-2 py-4 text-right">
                                                    {/*<TextField
                                                        className="w-full"
                                                        inputProps={{ min: 0, style: { textAlign: 'right' } }}
                                                        placeholder="ราคาต่อหน่วย"
                                                        variant="standard"
                                                        name="unitPrice"
                                                        type="number"
                                                        onChange={(evnt) => { handleChange(index, evnt) }}
                                                        value={data.unitPrice}
                                                    />*/}
                                                    <NumericFormat
                                                        thousandSeparator=","
                                                        className="border-0 outline-none w-full text-right pr-3.5"
                                                        placeholder="ราคาต่อหน่วย"
                                                        name="unitPrice"
                                                        onChange={(evnt) => {
                                                            handleChange(index, evnt)
                                                        }}
                                                        value={data.unitPrice}
                                                        onMouseDown={() => setIsDraggable(false)}
                                                        onMouseUp={() => setIsDraggable(true)}
                                                        decimalScale={2}
                                                        fixedDecimalScale
                                                    />
                                                </td>
                                                <td className="px-2 py-4 ">
                                                    {/*<TextField
                                                        className="w-full"
                                                        inputProps={{ min: 0, style: { textAlign: 'right' } }}
                                                        placeholder="ราคารวม"
                                                        variant="standard"
                                                        type="number"
                                                        value={data.inRowSumPrice}
                                                    />*/}
                                                    <NumericFormat
                                                        thousandSeparator=","
                                                        className="border-0 outline-none w-full text-right pr-3.5"
                                                        placeholder="ราคารวม"
                                                        value={data.inRowSumPrice}
                                                        decimalScale={2}
                                                        fixedDecimalScale
                                                    />
                                                </td>
                                                <td className="px-0 py-2 text-right w-2">
                                                    {(inputFields.length !== 1) ? <button
                                                        onClick={() => removeInputFields(index)}><CloseOutlinedIcon /></button> : ''}
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        <div className="mt-4">
                            <Button variant="outlined" onClick={addInputField}> + เพิ่มรายการ</Button>
                        </div>
                    </div>
                    <div className="py-10 px-10 grid grid-cols-5">
                        <div className="col-span-2">
                            <TextField
                                label="หมายเหตุ"
                                className="w-full"
                                placeholder="หมายเหตุ"
                                multiline
                                rows={6}
                                variant="standard"
                                type="text"
                                onChange={(evnt) => {
                                    setDocState(prev => ({
                                        ...prev,
                                        docRemark: evnt.target.value,
                                    }));
                                }}
                                value={docstate.docRemark}
                            />
                            <p className="my-4">{'{ ' + THBText(totalValue) + ' }'}</p>
                        </div>
                        <div></div>
                        <div></div>
                        <div className="mt-4">
                            <div className="grid grid-cols-2">
                                <div className="">รวมเป็นเงิน</div>
                                <div className="font-bold text-right">{fixedNUM(footerSum, 2)}</div>
                            </div>
                            <div className="grid grid-cols-2">
                                <div className="flex gap-4">
                                    ส่วนลด
                                    <input
                                        type="number"
                                        className="remove-spin w-6 outline-0"
                                        value={disCountPerc}
                                        onChange={(e) => setDisCountPerc(e.target.value)}
                                    /> %
                                </div>
                                <div className="font-bold text-right">
                                    <input
                                        className="text-right remove-spin w-20 outline-0"
                                        type="text"
                                        value={fixedNUM(disCountValue, 2)}
                                        onChange={(e) => setDisCountValue(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="grid grid-cols-2">
                                <div className="">ราคาหลังหักส่วนลด</div>
                                <div className="font-bold text-right">{fixedNUM(footerSum - disCountValue, 2)}</div>
                            </div>
                            {installment && installmentPerc < 100 &&
                                <div className="grid grid-cols-2">
                                    <div className="">แบ่งชำระ {installmentPerc}%</div>
                                    <div className="font-bold text-right">{fixedNUM(installmentValue, 2)}</div>
                                </div>
                            }

                            <div className="grid grid-cols-2 items-center">
                                <div className="flex items-center gap-2">
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Checkbox
                                                size="small"
                                                checked={docstate.defualtVat}
                                                value={docstate.defualtVat}
                                                onChange={() => {
                                                    setDocState(prev => ({
                                                        ...prev,
                                                        defualtVat: docstate.defualtVat ? false : true,

                                                    }));
                                                }}
                                            />} label="ภาษีมูลค่าเพิ่ม 7%" />
                                    </FormGroup>
                                </div>
                                <div className="font-bold text-right">{docstate.defualtVat ? fixedNUM(vatValue, 2) : '0.00'}</div>
                            </div>

                            <div className="grid grid-cols-2 pb-4 border-b border-b-slate-200">
                                <div className="">จำนวนเงินรวมทั้งสิ้น</div>
                                <div className="font-bold text-right">{docstate.defualtVat ? fixedNUM(totalValue, 2) : fixedNUM(footerSum - disCountValue, 2)}</div>
                            </div>
                            <div className="grid grid-cols-2 items-center">
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox
                                            checked={vatReduce}
                                            value={vatReduce}
                                            onChange={() => {
                                                if (vatReduce) {
                                                    setVatReduce(false)
                                                } else {
                                                    setVatReduce(true)
                                                }
                                            }}
                                        />} label="หักภาษี ณ ที่จ่าย 3%" />
                                </FormGroup>
                                {vatReduce && <div className="font-bold text-right"> {fixedNUM(vatReduceValue, 2)}</div>}
                            </div>
                            {vatReduce && <div className="grid grid-cols-2">
                                <div className="">ยอดชำระ</div>
                                <div className="font-bold text-right">{fixedNUM(ctmPay, 2)}</div>
                            </div>}
                        </div>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog
                open={openAlert}
                onClose={() => setOpenAlert(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"ยืนยันการลบข้อมูล"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        หากยืนยัน ข้อมูลจะถูกลบและไม่สามารถกู้คืนได้
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenAlert(false)}>ยกเลิก</Button>
                    <Button onClick={() => deleteData()} autoFocus>ยืนยัน</Button>
                </DialogActions>
            </Dialog>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={openNoti} autoHideDuration={6000} onClose={handleCloseNoti}>
                <Alert onClose={handleCloseNoti} severity={notiMsg.type} sx={{ width: '100%' }}>
                    {notiMsg.msg}
                </Alert>
            </Snackbar>


            <Dialog
                open={openCollectMoney}
                onClose={() => setOpenCollectMoney(false)}
                aria-labelledby="alert-dialog-title-5"
                aria-describedby="alert-dialog-description-5"
            >
                <DialogTitle id="alert-dialog-title-5">บันทึกการรับชำระเงิน</DialogTitle>
                <DialogContent>

                    <div className='mb-6' style={{ minWidth: '350px' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                renderInput={(props) =>
                                    <TextField
                                        {...props}
                                        variant="standard"
                                        fullWidth
                                    />
                                }
                                ampm={false}
                                label="วันที่รับชำระ"
                                inputFormat="YYYY/MM/DD"
                                value={collectMoneyDate || dayjs(new Date()).format('YYYY/MM/DD')}
                                onChange={(newValue) => {
                                    if (newValue !== null && newValue.$d !== 'Invalid Date') {
                                        setOpenMoneyDate(dayjs(newValue.$d).format('YYYY/MM/DD'));
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </div>
                    <div className='mb-6' style={{ minWidth: '350px' }}>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel id="demo-simple-select-label">วิธีการรับชำระ</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={collectMoneyMethod}
                                label="วิธีการรับชำระ"
                                onChange={(e) => setCollectMoneyMethod(e.target.value)}
                            >
                                <MenuItem value={1}>เงินสด</MenuItem>
                                <MenuItem value={2}>เช็ค</MenuItem>
                                <MenuItem value={3}>โอนเงิน</MenuItem>
                                <MenuItem value={4}>บัตรเครดิต</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenCollectMoney(false)}>ยกเลิก</Button>
                    <Button onClick={() => collectMoney()} disabled={disabled}>
                        บันทึกข้อมูล
                        {disabled && <CircularProgress size="1rem" />}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openCopyOrOriginal}
                onClose={() => setOpenCopyOrOriginal(false)}
                aria-labelledby="alert-dialog-title-6"
                aria-describedby="alert-dialog-description-6"
            >
                <DialogTitle id="alert-dialog-title-6">{copyOrOriginalDialog.title}</DialogTitle>
                <DialogContent>
                    <p>{copyOrOriginalDialog.tagline}</p>
                    <div className='my-6' style={{ minWidth: '350px' }}>
                        <FormControl variant="standard" fullWidth>
                            <Select
                                labelId="demo-simple-select-label-2"
                                id="demo-simple-select-2"
                                value={copyOrOriginal}
                                onChange={(e) => setCopyOrOriginal(e.target.value)}
                            >
                                <MenuItem value={1}>ต้นฉบับ</MenuItem>
                                <MenuItem value={2}>สำเนา</MenuItem>
                                <MenuItem value={3}>ต้นฉบับ และสำเนา</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenCopyOrOriginal(false)}>ยกเลิก</Button>
                    <Button onClick={() => handleDownloadOrPrint()} disabled={disabled}>
                        {copyOrOriginalDialog.button}
                        {disabled && <CircularProgress size="1rem" />}
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    )
}